// package: proto
// file: backend/v1/product_group_links.proto

var backend_v1_product_group_links_pb = require("../../backend/v1/product_group_links_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProductGroupLinkService = (function () {
  function ProductGroupLinkService() {}
  ProductGroupLinkService.serviceName = "proto.ProductGroupLinkService";
  return ProductGroupLinkService;
}());

ProductGroupLinkService.CreateProductGroupLink = {
  methodName: "CreateProductGroupLink",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.CreateProductGroupLinkRequest,
  responseType: backend_v1_product_group_links_pb.ProductGroupLink
};

ProductGroupLinkService.GetProductGroupLink = {
  methodName: "GetProductGroupLink",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.GetProductGroupLinkRequest,
  responseType: backend_v1_product_group_links_pb.ProductGroupLink
};

ProductGroupLinkService.UpdateProductGroupLink = {
  methodName: "UpdateProductGroupLink",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.UpdateProductGroupLinkRequest,
  responseType: backend_v1_product_group_links_pb.ProductGroupLink
};

ProductGroupLinkService.DeleteProductGroupLink = {
  methodName: "DeleteProductGroupLink",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.DeleteProductGroupLinkRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProductGroupLinkService.ListProductGroupLinkEvents = {
  methodName: "ListProductGroupLinkEvents",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.ListProductGroupLinkEventsRequest,
  responseType: backend_v1_product_group_links_pb.ListProductGroupLinkEventsResponse
};

ProductGroupLinkService.ListProductGroupLinks = {
  methodName: "ListProductGroupLinks",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.ListProductGroupLinksRequest,
  responseType: backend_v1_product_group_links_pb.ListProductGroupLinksResponse
};

ProductGroupLinkService.BatchGetProductGroupLinks = {
  methodName: "BatchGetProductGroupLinks",
  service: ProductGroupLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_group_links_pb.BatchGetProductGroupLinksRequest,
  responseType: backend_v1_product_group_links_pb.BatchGetProductGroupLinksResponse
};

exports.ProductGroupLinkService = ProductGroupLinkService;

function ProductGroupLinkServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProductGroupLinkServiceClient.prototype.createProductGroupLink = function createProductGroupLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.CreateProductGroupLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupLinkServiceClient.prototype.getProductGroupLink = function getProductGroupLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.GetProductGroupLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupLinkServiceClient.prototype.updateProductGroupLink = function updateProductGroupLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.UpdateProductGroupLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupLinkServiceClient.prototype.deleteProductGroupLink = function deleteProductGroupLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.DeleteProductGroupLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupLinkServiceClient.prototype.listProductGroupLinkEvents = function listProductGroupLinkEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.ListProductGroupLinkEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupLinkServiceClient.prototype.listProductGroupLinks = function listProductGroupLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.ListProductGroupLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupLinkServiceClient.prototype.batchGetProductGroupLinks = function batchGetProductGroupLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupLinkService.BatchGetProductGroupLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProductGroupLinkServiceClient = ProductGroupLinkServiceClient;

