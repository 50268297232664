// source: backend/v1/cart_items.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var backend_v1_common_pb = require('../../backend/v1/common_pb.js');
goog.object.extend(proto, backend_v1_common_pb);
goog.exportSymbol('proto.proto.BatchGetCartItemsRequest', null, global);
goog.exportSymbol('proto.proto.BatchGetCartItemsResponse', null, global);
goog.exportSymbol('proto.proto.CartItem', null, global);
goog.exportSymbol('proto.proto.CartItemEvent', null, global);
goog.exportSymbol('proto.proto.CartItemOrderBy', null, global);
goog.exportSymbol('proto.proto.CartItemUpdateMask', null, global);
goog.exportSymbol('proto.proto.CartModifier', null, global);
goog.exportSymbol('proto.proto.CreateCartItemRequest', null, global);
goog.exportSymbol('proto.proto.DeleteCartItemRequest', null, global);
goog.exportSymbol('proto.proto.GetCartItemRequest', null, global);
goog.exportSymbol('proto.proto.ListCartItemEventsRequest', null, global);
goog.exportSymbol('proto.proto.ListCartItemEventsResponse', null, global);
goog.exportSymbol('proto.proto.ListCartItemsRequest', null, global);
goog.exportSymbol('proto.proto.ListCartItemsResponse', null, global);
goog.exportSymbol('proto.proto.UpdateCartItemRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateCartItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateCartItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateCartItemRequest.displayName = 'proto.proto.CreateCartItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetCartItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetCartItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetCartItemRequest.displayName = 'proto.proto.GetCartItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateCartItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UpdateCartItemRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.UpdateCartItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateCartItemRequest.displayName = 'proto.proto.UpdateCartItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteCartItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteCartItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteCartItemRequest.displayName = 'proto.proto.DeleteCartItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListCartItemEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListCartItemEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListCartItemEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListCartItemEventsRequest.displayName = 'proto.proto.ListCartItemEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListCartItemEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListCartItemEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListCartItemEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListCartItemEventsResponse.displayName = 'proto.proto.ListCartItemEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListCartItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListCartItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListCartItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListCartItemsRequest.displayName = 'proto.proto.ListCartItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListCartItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListCartItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListCartItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListCartItemsResponse.displayName = 'proto.proto.ListCartItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetCartItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BatchGetCartItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.BatchGetCartItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetCartItemsRequest.displayName = 'proto.proto.BatchGetCartItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetCartItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BatchGetCartItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetCartItemsResponse.displayName = 'proto.proto.BatchGetCartItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CartItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CartItem.repeatedFields_, null);
};
goog.inherits(proto.proto.CartItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CartItem.displayName = 'proto.proto.CartItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CartModifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CartModifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CartModifier.displayName = 'proto.proto.CartModifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CartItemEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CartItemEvent.repeatedFields_, null);
};
goog.inherits(proto.proto.CartItemEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CartItemEvent.displayName = 'proto.proto.CartItemEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateCartItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateCartItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateCartItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateCartItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cartitem: (f = msg.getCartitem()) && proto.proto.CartItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateCartItemRequest}
 */
proto.proto.CreateCartItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateCartItemRequest;
  return proto.proto.CreateCartItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateCartItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateCartItemRequest}
 */
proto.proto.CreateCartItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.CartItem;
      reader.readMessage(value,proto.proto.CartItem.deserializeBinaryFromReader);
      msg.setCartitem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateCartItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateCartItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateCartItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateCartItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCartitem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.CartItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.CreateCartItemRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCartItemRequest} returns this
 */
proto.proto.CreateCartItemRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CartItem cartItem = 2;
 * @return {?proto.proto.CartItem}
 */
proto.proto.CreateCartItemRequest.prototype.getCartitem = function() {
  return /** @type{?proto.proto.CartItem} */ (
    jspb.Message.getWrapperField(this, proto.proto.CartItem, 2));
};


/**
 * @param {?proto.proto.CartItem|undefined} value
 * @return {!proto.proto.CreateCartItemRequest} returns this
*/
proto.proto.CreateCartItemRequest.prototype.setCartitem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateCartItemRequest} returns this
 */
proto.proto.CreateCartItemRequest.prototype.clearCartitem = function() {
  return this.setCartitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateCartItemRequest.prototype.hasCartitem = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetCartItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetCartItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetCartItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetCartItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetCartItemRequest}
 */
proto.proto.GetCartItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetCartItemRequest;
  return proto.proto.GetCartItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetCartItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetCartItemRequest}
 */
proto.proto.GetCartItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetCartItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetCartItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetCartItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetCartItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.GetCartItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetCartItemRequest} returns this
 */
proto.proto.GetCartItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UpdateCartItemRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateCartItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateCartItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateCartItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCartItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cartitem: (f = msg.getCartitem()) && proto.proto.CartItem.toObject(includeInstance, f),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateCartItemRequest}
 */
proto.proto.UpdateCartItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateCartItemRequest;
  return proto.proto.UpdateCartItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateCartItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateCartItemRequest}
 */
proto.proto.UpdateCartItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.CartItem;
      reader.readMessage(value,proto.proto.CartItem.deserializeBinaryFromReader);
      msg.setCartitem(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.CartItemUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateCartItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateCartItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateCartItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCartItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCartitem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.CartItem.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.UpdateCartItemRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCartItemRequest} returns this
 */
proto.proto.UpdateCartItemRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CartItem cartItem = 2;
 * @return {?proto.proto.CartItem}
 */
proto.proto.UpdateCartItemRequest.prototype.getCartitem = function() {
  return /** @type{?proto.proto.CartItem} */ (
    jspb.Message.getWrapperField(this, proto.proto.CartItem, 2));
};


/**
 * @param {?proto.proto.CartItem|undefined} value
 * @return {!proto.proto.UpdateCartItemRequest} returns this
*/
proto.proto.UpdateCartItemRequest.prototype.setCartitem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateCartItemRequest} returns this
 */
proto.proto.UpdateCartItemRequest.prototype.clearCartitem = function() {
  return this.setCartitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateCartItemRequest.prototype.hasCartitem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated CartItemUpdateMask update_masks = 3;
 * @return {!Array<!proto.proto.CartItemUpdateMask>}
 */
proto.proto.UpdateCartItemRequest.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.CartItemUpdateMask>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.CartItemUpdateMask>} value
 * @return {!proto.proto.UpdateCartItemRequest} returns this
 */
proto.proto.UpdateCartItemRequest.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.CartItemUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.UpdateCartItemRequest} returns this
 */
proto.proto.UpdateCartItemRequest.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UpdateCartItemRequest} returns this
 */
proto.proto.UpdateCartItemRequest.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteCartItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteCartItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteCartItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteCartItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteCartItemRequest}
 */
proto.proto.DeleteCartItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteCartItemRequest;
  return proto.proto.DeleteCartItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteCartItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteCartItemRequest}
 */
proto.proto.DeleteCartItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteCartItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteCartItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteCartItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteCartItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.DeleteCartItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteCartItemRequest} returns this
 */
proto.proto.DeleteCartItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListCartItemEventsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListCartItemEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListCartItemEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListCartItemEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListCartItemEventsRequest}
 */
proto.proto.ListCartItemEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListCartItemEventsRequest;
  return proto.proto.ListCartItemEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListCartItemEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListCartItemEventsRequest}
 */
proto.proto.ListCartItemEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.CartItemOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListCartItemEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListCartItemEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListCartItemEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.ListCartItemEventsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListCartItemEventsRequest} returns this
 */
proto.proto.ListCartItemEventsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.ListCartItemEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListCartItemEventsRequest} returns this
 */
proto.proto.ListCartItemEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 offset = 3;
 * @return {number}
 */
proto.proto.ListCartItemEventsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListCartItemEventsRequest} returns this
 */
proto.proto.ListCartItemEventsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CartItemOrderBy order_by = 4;
 * @return {!Array<!proto.proto.CartItemOrderBy>}
 */
proto.proto.ListCartItemEventsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.CartItemOrderBy>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.CartItemOrderBy>} value
 * @return {!proto.proto.ListCartItemEventsRequest} returns this
 */
proto.proto.ListCartItemEventsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.CartItemOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListCartItemEventsRequest} returns this
 */
proto.proto.ListCartItemEventsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemEventsRequest} returns this
 */
proto.proto.ListCartItemEventsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListCartItemEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListCartItemEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListCartItemEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListCartItemEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cartItemEventsList: jspb.Message.toObjectList(msg.getCartItemEventsList(),
    proto.proto.CartItemEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListCartItemEventsResponse}
 */
proto.proto.ListCartItemEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListCartItemEventsResponse;
  return proto.proto.ListCartItemEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListCartItemEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListCartItemEventsResponse}
 */
proto.proto.ListCartItemEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CartItemEvent;
      reader.readMessage(value,proto.proto.CartItemEvent.deserializeBinaryFromReader);
      msg.addCartItemEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListCartItemEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListCartItemEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListCartItemEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCartItemEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.CartItemEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CartItemEvent cart_item_events = 1;
 * @return {!Array<!proto.proto.CartItemEvent>}
 */
proto.proto.ListCartItemEventsResponse.prototype.getCartItemEventsList = function() {
  return /** @type{!Array<!proto.proto.CartItemEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CartItemEvent, 1));
};


/**
 * @param {!Array<!proto.proto.CartItemEvent>} value
 * @return {!proto.proto.ListCartItemEventsResponse} returns this
*/
proto.proto.ListCartItemEventsResponse.prototype.setCartItemEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.CartItemEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CartItemEvent}
 */
proto.proto.ListCartItemEventsResponse.prototype.addCartItemEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.CartItemEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemEventsResponse} returns this
 */
proto.proto.ListCartItemEventsResponse.prototype.clearCartItemEventsList = function() {
  return this.setCartItemEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListCartItemsRequest.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListCartItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListCartItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListCartItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    merchantIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    storeIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    cartIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    createTimestamp: (f = msg.getCreateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListCartItemsRequest}
 */
proto.proto.ListCartItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListCartItemsRequest;
  return proto.proto.ListCartItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListCartItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListCartItemsRequest}
 */
proto.proto.ListCartItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.CartItemOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addMerchantIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addStoreIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCartIds(value);
      break;
    case 8:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 9:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListCartItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListCartItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListCartItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getMerchantIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getStoreIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getCartIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 limit = 1;
 * @return {number}
 */
proto.proto.ListCartItemsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 offset = 2;
 * @return {number}
 */
proto.proto.ListCartItemsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CartItemOrderBy order_by = 3;
 * @return {!Array<!proto.proto.CartItemOrderBy>}
 */
proto.proto.ListCartItemsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.CartItemOrderBy>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.CartItemOrderBy>} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.CartItemOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};


/**
 * repeated string merchant_ids = 4;
 * @return {!Array<string>}
 */
proto.proto.ListCartItemsRequest.prototype.getMerchantIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setMerchantIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.addMerchantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearMerchantIdsList = function() {
  return this.setMerchantIdsList([]);
};


/**
 * repeated string store_ids = 5;
 * @return {!Array<string>}
 */
proto.proto.ListCartItemsRequest.prototype.getStoreIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setStoreIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.addStoreIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearStoreIdsList = function() {
  return this.setStoreIdsList([]);
};


/**
 * repeated string user_ids = 6;
 * @return {!Array<string>}
 */
proto.proto.ListCartItemsRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * repeated string cart_ids = 7;
 * @return {!Array<string>}
 */
proto.proto.ListCartItemsRequest.prototype.getCartIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.setCartIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.addCartIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearCartIdsList = function() {
  return this.setCartIdsList([]);
};


/**
 * optional TimestampRange create_timestamp = 8;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListCartItemsRequest.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 8));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
*/
proto.proto.ListCartItemsRequest.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListCartItemsRequest.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TimestampRange update_timestamp = 9;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListCartItemsRequest.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 9));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListCartItemsRequest} returns this
*/
proto.proto.ListCartItemsRequest.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListCartItemsRequest} returns this
 */
proto.proto.ListCartItemsRequest.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListCartItemsRequest.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListCartItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListCartItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListCartItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListCartItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cartItemsList: jspb.Message.toObjectList(msg.getCartItemsList(),
    proto.proto.CartItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListCartItemsResponse}
 */
proto.proto.ListCartItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListCartItemsResponse;
  return proto.proto.ListCartItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListCartItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListCartItemsResponse}
 */
proto.proto.ListCartItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CartItem;
      reader.readMessage(value,proto.proto.CartItem.deserializeBinaryFromReader);
      msg.addCartItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListCartItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListCartItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListCartItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCartItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCartItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.CartItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CartItem cart_items = 1;
 * @return {!Array<!proto.proto.CartItem>}
 */
proto.proto.ListCartItemsResponse.prototype.getCartItemsList = function() {
  return /** @type{!Array<!proto.proto.CartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CartItem, 1));
};


/**
 * @param {!Array<!proto.proto.CartItem>} value
 * @return {!proto.proto.ListCartItemsResponse} returns this
*/
proto.proto.ListCartItemsResponse.prototype.setCartItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.CartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CartItem}
 */
proto.proto.ListCartItemsResponse.prototype.addCartItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.CartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCartItemsResponse} returns this
 */
proto.proto.ListCartItemsResponse.prototype.clearCartItemsList = function() {
  return this.setCartItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BatchGetCartItemsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetCartItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetCartItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetCartItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetCartItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetCartItemsRequest}
 */
proto.proto.BatchGetCartItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetCartItemsRequest;
  return proto.proto.BatchGetCartItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetCartItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetCartItemsRequest}
 */
proto.proto.BatchGetCartItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetCartItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetCartItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetCartItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetCartItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.proto.BatchGetCartItemsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.BatchGetCartItemsRequest} returns this
 */
proto.proto.BatchGetCartItemsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.BatchGetCartItemsRequest} returns this
 */
proto.proto.BatchGetCartItemsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BatchGetCartItemsRequest} returns this
 */
proto.proto.BatchGetCartItemsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetCartItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetCartItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetCartItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetCartItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cartitemsMap: (f = msg.getCartitemsMap()) ? f.toObject(includeInstance, proto.proto.CartItem.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetCartItemsResponse}
 */
proto.proto.BatchGetCartItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetCartItemsResponse;
  return proto.proto.BatchGetCartItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetCartItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetCartItemsResponse}
 */
proto.proto.BatchGetCartItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCartitemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.proto.CartItem.deserializeBinaryFromReader, "", new proto.proto.CartItem());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetCartItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetCartItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetCartItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetCartItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCartitemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.proto.CartItem.serializeBinaryToWriter);
  }
};


/**
 * map<string, CartItem> cartItems = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.proto.CartItem>}
 */
proto.proto.BatchGetCartItemsResponse.prototype.getCartitemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.proto.CartItem>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.proto.CartItem));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.proto.BatchGetCartItemsResponse} returns this
 */
proto.proto.BatchGetCartItemsResponse.prototype.clearCartitemsMap = function() {
  this.getCartitemsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CartItem.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CartItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CartItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CartItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CartItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createTimestamp: (f = msg.getCreateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    merchantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    storeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cartId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productTitle: jspb.Message.getFieldWithDefault(msg, 9, ""),
    productGroupId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    productGroupTitle: jspb.Message.getFieldWithDefault(msg, 11, ""),
    variationId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    variationTitle: jspb.Message.getFieldWithDefault(msg, 13, ""),
    variationPrice: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cartModifiersList: jspb.Message.toObjectList(msg.getCartModifiersList(),
    proto.proto.CartModifier.toObject, includeInstance),
    quantity: jspb.Message.getFieldWithDefault(msg, 16, 0),
    unitPrice: jspb.Message.getFieldWithDefault(msg, 17, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CartItem}
 */
proto.proto.CartItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CartItem;
  return proto.proto.CartItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CartItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CartItem}
 */
proto.proto.CartItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCartId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductTitle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductGroupId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductGroupTitle(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariationId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariationTitle(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVariationPrice(value);
      break;
    case 15:
      var value = new proto.proto.CartModifier;
      reader.readMessage(value,proto.proto.CartModifier.deserializeBinaryFromReader);
      msg.addCartModifiers(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUnitPrice(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CartItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CartItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CartItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CartItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStoreId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCartId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProductGroupId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProductGroupTitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVariationId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVariationTitle();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVariationPrice();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getCartModifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.proto.CartModifier.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getUnitPrice();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeUint64(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.CartItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp update_timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.CartItem.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.CartItem} returns this
*/
proto.proto.CartItem.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CartItem.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.CartItem.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.CartItem} returns this
*/
proto.proto.CartItem.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CartItem.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string merchant_id = 4;
 * @return {string}
 */
proto.proto.CartItem.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string store_id = 5;
 * @return {string}
 */
proto.proto.CartItem.prototype.getStoreId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.proto.CartItem.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cart_id = 7;
 * @return {string}
 */
proto.proto.CartItem.prototype.getCartId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setCartId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string product_id = 8;
 * @return {string}
 */
proto.proto.CartItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_title = 9;
 * @return {string}
 */
proto.proto.CartItem.prototype.getProductTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setProductTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string product_group_id = 10;
 * @return {string}
 */
proto.proto.CartItem.prototype.getProductGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setProductGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string product_group_title = 11;
 * @return {string}
 */
proto.proto.CartItem.prototype.getProductGroupTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setProductGroupTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string variation_id = 12;
 * @return {string}
 */
proto.proto.CartItem.prototype.getVariationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setVariationId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string variation_title = 13;
 * @return {string}
 */
proto.proto.CartItem.prototype.getVariationTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setVariationTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint64 variation_price = 14;
 * @return {number}
 */
proto.proto.CartItem.prototype.getVariationPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setVariationPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated CartModifier cart_modifiers = 15;
 * @return {!Array<!proto.proto.CartModifier>}
 */
proto.proto.CartItem.prototype.getCartModifiersList = function() {
  return /** @type{!Array<!proto.proto.CartModifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CartModifier, 15));
};


/**
 * @param {!Array<!proto.proto.CartModifier>} value
 * @return {!proto.proto.CartItem} returns this
*/
proto.proto.CartItem.prototype.setCartModifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.proto.CartModifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CartModifier}
 */
proto.proto.CartItem.prototype.addCartModifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.proto.CartModifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.clearCartModifiersList = function() {
  return this.setCartModifiersList([]);
};


/**
 * optional uint32 quantity = 16;
 * @return {number}
 */
proto.proto.CartItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint64 unit_price = 17;
 * @return {number}
 */
proto.proto.CartItem.prototype.getUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint64 total_price = 18;
 * @return {number}
 */
proto.proto.CartItem.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartItem} returns this
 */
proto.proto.CartItem.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CartModifier.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CartModifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CartModifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CartModifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifierId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modifierTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modifierGroupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unitPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CartModifier}
 */
proto.proto.CartModifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CartModifier;
  return proto.proto.CartModifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CartModifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CartModifier}
 */
proto.proto.CartModifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifierId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifierTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifierGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUnitPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CartModifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CartModifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CartModifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CartModifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifierId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModifierTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModifierGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getUnitPrice();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional string modifier_id = 1;
 * @return {string}
 */
proto.proto.CartModifier.prototype.getModifierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartModifier} returns this
 */
proto.proto.CartModifier.prototype.setModifierId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string modifier_title = 2;
 * @return {string}
 */
proto.proto.CartModifier.prototype.getModifierTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartModifier} returns this
 */
proto.proto.CartModifier.prototype.setModifierTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string modifier_group_id = 3;
 * @return {string}
 */
proto.proto.CartModifier.prototype.getModifierGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartModifier} returns this
 */
proto.proto.CartModifier.prototype.setModifierGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 quantity = 4;
 * @return {number}
 */
proto.proto.CartModifier.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartModifier} returns this
 */
proto.proto.CartModifier.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 unit_price = 5;
 * @return {number}
 */
proto.proto.CartModifier.prototype.getUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartModifier} returns this
 */
proto.proto.CartModifier.prototype.setUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 total_price = 6;
 * @return {number}
 */
proto.proto.CartModifier.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartModifier} returns this
 */
proto.proto.CartModifier.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CartItemEvent.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CartItemEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CartItemEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CartItemEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CartItemEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cartitem: (f = msg.getCartitem()) && proto.proto.CartItem.toObject(includeInstance, f),
    changeNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CartItemEvent}
 */
proto.proto.CartItemEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CartItemEvent;
  return proto.proto.CartItemEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CartItemEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CartItemEvent}
 */
proto.proto.CartItemEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.CartItem;
      reader.readMessage(value,proto.proto.CartItem.deserializeBinaryFromReader);
      msg.setCartitem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChangeNumber(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.CartItemUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    case 5:
      var value = /** @type {!proto.proto.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CartItemEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CartItemEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CartItemEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CartItemEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCartitem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.CartItem.serializeBinaryToWriter
    );
  }
  f = message.getChangeNumber();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.CartItemEvent.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CartItem cartItem = 2;
 * @return {?proto.proto.CartItem}
 */
proto.proto.CartItemEvent.prototype.getCartitem = function() {
  return /** @type{?proto.proto.CartItem} */ (
    jspb.Message.getWrapperField(this, proto.proto.CartItem, 2));
};


/**
 * @param {?proto.proto.CartItem|undefined} value
 * @return {!proto.proto.CartItemEvent} returns this
*/
proto.proto.CartItemEvent.prototype.setCartitem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.clearCartitem = function() {
  return this.setCartitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CartItemEvent.prototype.hasCartitem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 change_number = 3;
 * @return {number}
 */
proto.proto.CartItemEvent.prototype.getChangeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.setChangeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CartItemUpdateMask update_masks = 4;
 * @return {!Array<!proto.proto.CartItemUpdateMask>}
 */
proto.proto.CartItemEvent.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.CartItemUpdateMask>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.CartItemUpdateMask>} value
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.CartItemUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};


/**
 * optional EventType event_type = 5;
 * @return {!proto.proto.EventType}
 */
proto.proto.CartItemEvent.prototype.getEventType = function() {
  return /** @type {!proto.proto.EventType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.EventType} value
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool processed = 6;
 * @return {boolean}
 */
proto.proto.CartItemEvent.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CartItemEvent} returns this
 */
proto.proto.CartItemEvent.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.proto.CartItemUpdateMask = {
  CART_ITEM_UPDATE_MASK_UNKNOWN: 0,
  CART_ITEM_UPDATE_MASK_USER_ID: 1,
  CART_ITEM_UPDATE_MASK_VARIATION_ID: 2,
  CART_ITEM_UPDATE_MASK_CART_MODIFIERS: 3,
  CART_ITEM_UPDATE_MASK_QUANTITY: 4
};

/**
 * @enum {number}
 */
proto.proto.CartItemOrderBy = {
  CART_ITEM_ORDER_BY_UNKNOWN: 0,
  CART_ITEM_ORDER_BY_UPDATE_TIMESTAMP_ASC: 1,
  CART_ITEM_ORDER_BY_UPDATE_TIMESTAMP_DESC: 2,
  CART_ITEM_ORDER_BY_CREATE_TIMESTAMP_ASC: 3,
  CART_ITEM_ORDER_BY_CREATE_TIMESTAMP_DESC: 4
};

goog.object.extend(exports, proto.proto);
