// package: proto
// file: backend/v1/cart_items.proto

var backend_v1_cart_items_pb = require("../../backend/v1/cart_items_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CartItemService = (function () {
  function CartItemService() {}
  CartItemService.serviceName = "proto.CartItemService";
  return CartItemService;
}());

CartItemService.CreateCartItem = {
  methodName: "CreateCartItem",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.CreateCartItemRequest,
  responseType: backend_v1_cart_items_pb.CartItem
};

CartItemService.GetCartItem = {
  methodName: "GetCartItem",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.GetCartItemRequest,
  responseType: backend_v1_cart_items_pb.CartItem
};

CartItemService.UpdateCartItem = {
  methodName: "UpdateCartItem",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.UpdateCartItemRequest,
  responseType: backend_v1_cart_items_pb.CartItem
};

CartItemService.DeleteCartItem = {
  methodName: "DeleteCartItem",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.DeleteCartItemRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CartItemService.ListCartItemEvents = {
  methodName: "ListCartItemEvents",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.ListCartItemEventsRequest,
  responseType: backend_v1_cart_items_pb.ListCartItemEventsResponse
};

CartItemService.ListCartItems = {
  methodName: "ListCartItems",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.ListCartItemsRequest,
  responseType: backend_v1_cart_items_pb.ListCartItemsResponse
};

CartItemService.BatchGetCartItems = {
  methodName: "BatchGetCartItems",
  service: CartItemService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_cart_items_pb.BatchGetCartItemsRequest,
  responseType: backend_v1_cart_items_pb.BatchGetCartItemsResponse
};

exports.CartItemService = CartItemService;

function CartItemServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CartItemServiceClient.prototype.createCartItem = function createCartItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.CreateCartItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartItemServiceClient.prototype.getCartItem = function getCartItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.GetCartItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartItemServiceClient.prototype.updateCartItem = function updateCartItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.UpdateCartItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartItemServiceClient.prototype.deleteCartItem = function deleteCartItem(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.DeleteCartItem, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartItemServiceClient.prototype.listCartItemEvents = function listCartItemEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.ListCartItemEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartItemServiceClient.prototype.listCartItems = function listCartItems(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.ListCartItems, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartItemServiceClient.prototype.batchGetCartItems = function batchGetCartItems(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartItemService.BatchGetCartItems, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CartItemServiceClient = CartItemServiceClient;

