// package: proto
// file: backend/v1/service.proto

var backend_v1_service_pb = require("../../backend/v1/service_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Emreat = (function () {
  function Emreat() {}
  Emreat.serviceName = "proto.Emreat";
  return Emreat;
}());

Emreat.GetVersion = {
  methodName: "GetVersion",
  service: Emreat,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: backend_v1_service_pb.GetVersionResponse
};

Emreat.GetAppVersion = {
  methodName: "GetAppVersion",
  service: Emreat,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: backend_v1_service_pb.GetAppVersionResponse
};

exports.Emreat = Emreat;

function EmreatClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

EmreatClient.prototype.getVersion = function getVersion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Emreat.GetVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EmreatClient.prototype.getAppVersion = function getAppVersion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Emreat.GetAppVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.EmreatClient = EmreatClient;

