// package: proto
// file: backend/v1/devices.proto

var backend_v1_devices_pb = require("../../backend/v1/devices_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var DeviceService = (function () {
  function DeviceService() {}
  DeviceService.serviceName = "proto.DeviceService";
  return DeviceService;
}());

DeviceService.CreateDevice = {
  methodName: "CreateDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.CreateDeviceRequest,
  responseType: backend_v1_devices_pb.Device
};

DeviceService.GetDevice = {
  methodName: "GetDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.GetDeviceRequest,
  responseType: backend_v1_devices_pb.Device
};

DeviceService.UpdateDevice = {
  methodName: "UpdateDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.UpdateDeviceRequest,
  responseType: backend_v1_devices_pb.Device
};

DeviceService.DeleteDevice = {
  methodName: "DeleteDevice",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.DeleteDeviceRequest,
  responseType: google_protobuf_empty_pb.Empty
};

DeviceService.ListDeviceEvents = {
  methodName: "ListDeviceEvents",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.ListDeviceEventsRequest,
  responseType: backend_v1_devices_pb.ListDeviceEventsResponse
};

DeviceService.ListDevices = {
  methodName: "ListDevices",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.ListDevicesRequest,
  responseType: backend_v1_devices_pb.ListDevicesResponse
};

DeviceService.BatchGetDevices = {
  methodName: "BatchGetDevices",
  service: DeviceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_devices_pb.BatchGetDevicesRequest,
  responseType: backend_v1_devices_pb.BatchGetDevicesResponse
};

exports.DeviceService = DeviceService;

function DeviceServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DeviceServiceClient.prototype.createDevice = function createDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.CreateDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.getDevice = function getDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.GetDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.updateDevice = function updateDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.UpdateDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.deleteDevice = function deleteDevice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.DeleteDevice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.listDeviceEvents = function listDeviceEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.ListDeviceEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.listDevices = function listDevices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.ListDevices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

DeviceServiceClient.prototype.batchGetDevices = function batchGetDevices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DeviceService.BatchGetDevices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DeviceServiceClient = DeviceServiceClient;

