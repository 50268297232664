// source: backend/v1/ratings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var backend_v1_common_pb = require('../../backend/v1/common_pb.js');
goog.object.extend(proto, backend_v1_common_pb);
goog.exportSymbol('proto.proto.AdjustRatingRequest', null, global);
goog.exportSymbol('proto.proto.BatchGetRatingsRequest', null, global);
goog.exportSymbol('proto.proto.BatchGetRatingsResponse', null, global);
goog.exportSymbol('proto.proto.CreateRatingRequest', null, global);
goog.exportSymbol('proto.proto.DeleteRatingRequest', null, global);
goog.exportSymbol('proto.proto.GetRatingRequest', null, global);
goog.exportSymbol('proto.proto.ListRatingEventsRequest', null, global);
goog.exportSymbol('proto.proto.ListRatingEventsResponse', null, global);
goog.exportSymbol('proto.proto.ListRatingsRequest', null, global);
goog.exportSymbol('proto.proto.ListRatingsResponse', null, global);
goog.exportSymbol('proto.proto.Rating', null, global);
goog.exportSymbol('proto.proto.RatingEvent', null, global);
goog.exportSymbol('proto.proto.RatingOrderBy', null, global);
goog.exportSymbol('proto.proto.RatingUpdateMask', null, global);
goog.exportSymbol('proto.proto.UpdateRatingRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateRatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateRatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateRatingRequest.displayName = 'proto.proto.CreateRatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetRatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetRatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetRatingRequest.displayName = 'proto.proto.GetRatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateRatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UpdateRatingRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.UpdateRatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateRatingRequest.displayName = 'proto.proto.UpdateRatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteRatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteRatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteRatingRequest.displayName = 'proto.proto.DeleteRatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRatingEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListRatingEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListRatingEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRatingEventsRequest.displayName = 'proto.proto.ListRatingEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRatingEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListRatingEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListRatingEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRatingEventsResponse.displayName = 'proto.proto.ListRatingEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRatingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListRatingsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListRatingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRatingsRequest.displayName = 'proto.proto.ListRatingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRatingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListRatingsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListRatingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRatingsResponse.displayName = 'proto.proto.ListRatingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetRatingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BatchGetRatingsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.BatchGetRatingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetRatingsRequest.displayName = 'proto.proto.BatchGetRatingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetRatingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BatchGetRatingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetRatingsResponse.displayName = 'proto.proto.BatchGetRatingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AdjustRatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AdjustRatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AdjustRatingRequest.displayName = 'proto.proto.AdjustRatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Rating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Rating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Rating.displayName = 'proto.proto.Rating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RatingEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.RatingEvent.repeatedFields_, null);
};
goog.inherits(proto.proto.RatingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RatingEvent.displayName = 'proto.proto.RatingEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateRatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateRatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateRatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateRatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rating: (f = msg.getRating()) && proto.proto.Rating.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateRatingRequest}
 */
proto.proto.CreateRatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateRatingRequest;
  return proto.proto.CreateRatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateRatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateRatingRequest}
 */
proto.proto.CreateRatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Rating;
      reader.readMessage(value,proto.proto.Rating.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateRatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateRatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateRatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateRatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Rating.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.CreateRatingRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRatingRequest} returns this
 */
proto.proto.CreateRatingRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Rating rating = 2;
 * @return {?proto.proto.Rating}
 */
proto.proto.CreateRatingRequest.prototype.getRating = function() {
  return /** @type{?proto.proto.Rating} */ (
    jspb.Message.getWrapperField(this, proto.proto.Rating, 2));
};


/**
 * @param {?proto.proto.Rating|undefined} value
 * @return {!proto.proto.CreateRatingRequest} returns this
*/
proto.proto.CreateRatingRequest.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateRatingRequest} returns this
 */
proto.proto.CreateRatingRequest.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateRatingRequest.prototype.hasRating = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetRatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetRatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetRatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetRatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetRatingRequest}
 */
proto.proto.GetRatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetRatingRequest;
  return proto.proto.GetRatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetRatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetRatingRequest}
 */
proto.proto.GetRatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetRatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetRatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetRatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetRatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.GetRatingRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetRatingRequest} returns this
 */
proto.proto.GetRatingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UpdateRatingRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateRatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateRatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateRatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateRatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rating: (f = msg.getRating()) && proto.proto.Rating.toObject(includeInstance, f),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateRatingRequest}
 */
proto.proto.UpdateRatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateRatingRequest;
  return proto.proto.UpdateRatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateRatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateRatingRequest}
 */
proto.proto.UpdateRatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Rating;
      reader.readMessage(value,proto.proto.Rating.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.RatingUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateRatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateRatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateRatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateRatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Rating.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.UpdateRatingRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRatingRequest} returns this
 */
proto.proto.UpdateRatingRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Rating rating = 2;
 * @return {?proto.proto.Rating}
 */
proto.proto.UpdateRatingRequest.prototype.getRating = function() {
  return /** @type{?proto.proto.Rating} */ (
    jspb.Message.getWrapperField(this, proto.proto.Rating, 2));
};


/**
 * @param {?proto.proto.Rating|undefined} value
 * @return {!proto.proto.UpdateRatingRequest} returns this
*/
proto.proto.UpdateRatingRequest.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateRatingRequest} returns this
 */
proto.proto.UpdateRatingRequest.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateRatingRequest.prototype.hasRating = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RatingUpdateMask update_masks = 3;
 * @return {!Array<!proto.proto.RatingUpdateMask>}
 */
proto.proto.UpdateRatingRequest.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.RatingUpdateMask>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.RatingUpdateMask>} value
 * @return {!proto.proto.UpdateRatingRequest} returns this
 */
proto.proto.UpdateRatingRequest.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.RatingUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.UpdateRatingRequest} returns this
 */
proto.proto.UpdateRatingRequest.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UpdateRatingRequest} returns this
 */
proto.proto.UpdateRatingRequest.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteRatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteRatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteRatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteRatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteRatingRequest}
 */
proto.proto.DeleteRatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteRatingRequest;
  return proto.proto.DeleteRatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteRatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteRatingRequest}
 */
proto.proto.DeleteRatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteRatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteRatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteRatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteRatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.DeleteRatingRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteRatingRequest} returns this
 */
proto.proto.DeleteRatingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListRatingEventsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRatingEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRatingEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRatingEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRatingEventsRequest}
 */
proto.proto.ListRatingEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRatingEventsRequest;
  return proto.proto.ListRatingEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRatingEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRatingEventsRequest}
 */
proto.proto.ListRatingEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.RatingOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRatingEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRatingEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRatingEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.ListRatingEventsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRatingEventsRequest} returns this
 */
proto.proto.ListRatingEventsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.ListRatingEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListRatingEventsRequest} returns this
 */
proto.proto.ListRatingEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 offset = 3;
 * @return {number}
 */
proto.proto.ListRatingEventsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListRatingEventsRequest} returns this
 */
proto.proto.ListRatingEventsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated RatingOrderBy order_by = 4;
 * @return {!Array<!proto.proto.RatingOrderBy>}
 */
proto.proto.ListRatingEventsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.RatingOrderBy>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.RatingOrderBy>} value
 * @return {!proto.proto.ListRatingEventsRequest} returns this
 */
proto.proto.ListRatingEventsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.RatingOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListRatingEventsRequest} returns this
 */
proto.proto.ListRatingEventsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRatingEventsRequest} returns this
 */
proto.proto.ListRatingEventsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListRatingEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRatingEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRatingEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRatingEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratingEventsList: jspb.Message.toObjectList(msg.getRatingEventsList(),
    proto.proto.RatingEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRatingEventsResponse}
 */
proto.proto.ListRatingEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRatingEventsResponse;
  return proto.proto.ListRatingEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRatingEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRatingEventsResponse}
 */
proto.proto.ListRatingEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RatingEvent;
      reader.readMessage(value,proto.proto.RatingEvent.deserializeBinaryFromReader);
      msg.addRatingEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRatingEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRatingEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRatingEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatingEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.RatingEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RatingEvent rating_events = 1;
 * @return {!Array<!proto.proto.RatingEvent>}
 */
proto.proto.ListRatingEventsResponse.prototype.getRatingEventsList = function() {
  return /** @type{!Array<!proto.proto.RatingEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.RatingEvent, 1));
};


/**
 * @param {!Array<!proto.proto.RatingEvent>} value
 * @return {!proto.proto.ListRatingEventsResponse} returns this
*/
proto.proto.ListRatingEventsResponse.prototype.setRatingEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.RatingEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.RatingEvent}
 */
proto.proto.ListRatingEventsResponse.prototype.addRatingEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.RatingEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRatingEventsResponse} returns this
 */
proto.proto.ListRatingEventsResponse.prototype.clearRatingEventsList = function() {
  return this.setRatingEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListRatingsRequest.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRatingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRatingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRatingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    merchantIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    storeIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    createTimestamp: (f = msg.getCreateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRatingsRequest}
 */
proto.proto.ListRatingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRatingsRequest;
  return proto.proto.ListRatingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRatingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRatingsRequest}
 */
proto.proto.ListRatingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.RatingOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addMerchantIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addStoreIds(value);
      break;
    case 6:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 7:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRatingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRatingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRatingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getMerchantIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getStoreIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 limit = 1;
 * @return {number}
 */
proto.proto.ListRatingsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 offset = 2;
 * @return {number}
 */
proto.proto.ListRatingsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated RatingOrderBy order_by = 3;
 * @return {!Array<!proto.proto.RatingOrderBy>}
 */
proto.proto.ListRatingsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.RatingOrderBy>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.RatingOrderBy>} value
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.RatingOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};


/**
 * repeated string merchant_ids = 4;
 * @return {!Array<string>}
 */
proto.proto.ListRatingsRequest.prototype.getMerchantIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.setMerchantIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.addMerchantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.clearMerchantIdsList = function() {
  return this.setMerchantIdsList([]);
};


/**
 * repeated string store_ids = 5;
 * @return {!Array<string>}
 */
proto.proto.ListRatingsRequest.prototype.getStoreIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.setStoreIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.addStoreIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.clearStoreIdsList = function() {
  return this.setStoreIdsList([]);
};


/**
 * optional TimestampRange create_timestamp = 6;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListRatingsRequest.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 6));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListRatingsRequest} returns this
*/
proto.proto.ListRatingsRequest.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListRatingsRequest.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TimestampRange update_timestamp = 7;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListRatingsRequest.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 7));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListRatingsRequest} returns this
*/
proto.proto.ListRatingsRequest.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListRatingsRequest} returns this
 */
proto.proto.ListRatingsRequest.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListRatingsRequest.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListRatingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRatingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRatingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRatingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratingsList: jspb.Message.toObjectList(msg.getRatingsList(),
    proto.proto.Rating.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRatingsResponse}
 */
proto.proto.ListRatingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRatingsResponse;
  return proto.proto.ListRatingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRatingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRatingsResponse}
 */
proto.proto.ListRatingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Rating;
      reader.readMessage(value,proto.proto.Rating.deserializeBinaryFromReader);
      msg.addRatings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRatingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRatingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRatingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRatingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Rating.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Rating ratings = 1;
 * @return {!Array<!proto.proto.Rating>}
 */
proto.proto.ListRatingsResponse.prototype.getRatingsList = function() {
  return /** @type{!Array<!proto.proto.Rating>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Rating, 1));
};


/**
 * @param {!Array<!proto.proto.Rating>} value
 * @return {!proto.proto.ListRatingsResponse} returns this
*/
proto.proto.ListRatingsResponse.prototype.setRatingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Rating=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Rating}
 */
proto.proto.ListRatingsResponse.prototype.addRatings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Rating, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRatingsResponse} returns this
 */
proto.proto.ListRatingsResponse.prototype.clearRatingsList = function() {
  return this.setRatingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BatchGetRatingsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetRatingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetRatingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetRatingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetRatingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetRatingsRequest}
 */
proto.proto.BatchGetRatingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetRatingsRequest;
  return proto.proto.BatchGetRatingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetRatingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetRatingsRequest}
 */
proto.proto.BatchGetRatingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetRatingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetRatingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetRatingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetRatingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.proto.BatchGetRatingsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.BatchGetRatingsRequest} returns this
 */
proto.proto.BatchGetRatingsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.BatchGetRatingsRequest} returns this
 */
proto.proto.BatchGetRatingsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BatchGetRatingsRequest} returns this
 */
proto.proto.BatchGetRatingsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetRatingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetRatingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetRatingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetRatingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ratingsMap: (f = msg.getRatingsMap()) ? f.toObject(includeInstance, proto.proto.Rating.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetRatingsResponse}
 */
proto.proto.BatchGetRatingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetRatingsResponse;
  return proto.proto.BatchGetRatingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetRatingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetRatingsResponse}
 */
proto.proto.BatchGetRatingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getRatingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.proto.Rating.deserializeBinaryFromReader, "", new proto.proto.Rating());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetRatingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetRatingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetRatingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetRatingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.proto.Rating.serializeBinaryToWriter);
  }
};


/**
 * map<string, Rating> ratings = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.proto.Rating>}
 */
proto.proto.BatchGetRatingsResponse.prototype.getRatingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.proto.Rating>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.proto.Rating));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.proto.BatchGetRatingsResponse} returns this
 */
proto.proto.BatchGetRatingsResponse.prototype.clearRatingsMap = function() {
  this.getRatingsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AdjustRatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AdjustRatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AdjustRatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AdjustRatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    score: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AdjustRatingRequest}
 */
proto.proto.AdjustRatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AdjustRatingRequest;
  return proto.proto.AdjustRatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AdjustRatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AdjustRatingRequest}
 */
proto.proto.AdjustRatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AdjustRatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AdjustRatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AdjustRatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AdjustRatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.AdjustRatingRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AdjustRatingRequest} returns this
 */
proto.proto.AdjustRatingRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.proto.AdjustRatingRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AdjustRatingRequest} returns this
 */
proto.proto.AdjustRatingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 score = 3;
 * @return {number}
 */
proto.proto.AdjustRatingRequest.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AdjustRatingRequest} returns this
 */
proto.proto.AdjustRatingRequest.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Rating.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Rating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Rating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Rating.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createTimestamp: (f = msg.getCreateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    merchantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    storeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    score: jspb.Message.getFieldWithDefault(msg, 6, 0),
    count: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Rating}
 */
proto.proto.Rating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Rating;
  return proto.proto.Rating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Rating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Rating}
 */
proto.proto.Rating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Rating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Rating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Rating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Rating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStoreId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Rating.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp update_timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Rating.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Rating} returns this
*/
proto.proto.Rating.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Rating.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Rating.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Rating} returns this
*/
proto.proto.Rating.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Rating.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string merchant_id = 4;
 * @return {string}
 */
proto.proto.Rating.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string store_id = 5;
 * @return {string}
 */
proto.proto.Rating.prototype.getStoreId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 score = 6;
 * @return {number}
 */
proto.proto.Rating.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 count = 7;
 * @return {number}
 */
proto.proto.Rating.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Rating} returns this
 */
proto.proto.Rating.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.RatingEvent.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RatingEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RatingEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RatingEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RatingEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rating: (f = msg.getRating()) && proto.proto.Rating.toObject(includeInstance, f),
    changeNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RatingEvent}
 */
proto.proto.RatingEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RatingEvent;
  return proto.proto.RatingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RatingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RatingEvent}
 */
proto.proto.RatingEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Rating;
      reader.readMessage(value,proto.proto.Rating.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChangeNumber(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.RatingUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    case 5:
      var value = /** @type {!proto.proto.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RatingEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RatingEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RatingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RatingEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Rating.serializeBinaryToWriter
    );
  }
  f = message.getChangeNumber();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.RatingEvent.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Rating rating = 2;
 * @return {?proto.proto.Rating}
 */
proto.proto.RatingEvent.prototype.getRating = function() {
  return /** @type{?proto.proto.Rating} */ (
    jspb.Message.getWrapperField(this, proto.proto.Rating, 2));
};


/**
 * @param {?proto.proto.Rating|undefined} value
 * @return {!proto.proto.RatingEvent} returns this
*/
proto.proto.RatingEvent.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RatingEvent.prototype.hasRating = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 change_number = 3;
 * @return {number}
 */
proto.proto.RatingEvent.prototype.getChangeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.setChangeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated RatingUpdateMask update_masks = 4;
 * @return {!Array<!proto.proto.RatingUpdateMask>}
 */
proto.proto.RatingEvent.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.RatingUpdateMask>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.RatingUpdateMask>} value
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.RatingUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};


/**
 * optional EventType event_type = 5;
 * @return {!proto.proto.EventType}
 */
proto.proto.RatingEvent.prototype.getEventType = function() {
  return /** @type {!proto.proto.EventType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.EventType} value
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool processed = 6;
 * @return {boolean}
 */
proto.proto.RatingEvent.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.RatingEvent} returns this
 */
proto.proto.RatingEvent.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.proto.RatingUpdateMask = {
  RATING_UPDATE_MASK_UNKNOWN: 0,
  RATING_UPDATE_MASK_SCORE: 1,
  RATING_UPDATE_MASK_COUNT: 2
};

/**
 * @enum {number}
 */
proto.proto.RatingOrderBy = {
  RATING_ORDER_BY_UNKNOWN: 0,
  RATING_ORDER_BY_UPDATE_TIMESTAMP_ASC: 1,
  RATING_ORDER_BY_UPDATE_TIMESTAMP_DESC: 2,
  RATING_ORDER_BY_CREATE_TIMESTAMP_ASC: 3,
  RATING_ORDER_BY_CREATE_TIMESTAMP_DESC: 4
};

goog.object.extend(exports, proto.proto);
