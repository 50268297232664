// package: proto
// file: backend/v1/balances.proto

var backend_v1_balances_pb = require("../../backend/v1/balances_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BalanceService = (function () {
  function BalanceService() {}
  BalanceService.serviceName = "proto.BalanceService";
  return BalanceService;
}());

BalanceService.CreateBalance = {
  methodName: "CreateBalance",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.CreateBalanceRequest,
  responseType: backend_v1_balances_pb.Balance
};

BalanceService.GetBalance = {
  methodName: "GetBalance",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.GetBalanceRequest,
  responseType: backend_v1_balances_pb.Balance
};

BalanceService.UpdateBalance = {
  methodName: "UpdateBalance",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.UpdateBalanceRequest,
  responseType: backend_v1_balances_pb.Balance
};

BalanceService.DeleteBalance = {
  methodName: "DeleteBalance",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.DeleteBalanceRequest,
  responseType: google_protobuf_empty_pb.Empty
};

BalanceService.ListBalanceEvents = {
  methodName: "ListBalanceEvents",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.ListBalanceEventsRequest,
  responseType: backend_v1_balances_pb.ListBalanceEventsResponse
};

BalanceService.ListBalances = {
  methodName: "ListBalances",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.ListBalancesRequest,
  responseType: backend_v1_balances_pb.ListBalancesResponse
};

BalanceService.BatchGetBalances = {
  methodName: "BatchGetBalances",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.BatchGetBalancesRequest,
  responseType: backend_v1_balances_pb.BatchGetBalancesResponse
};

BalanceService.AdjustBalance = {
  methodName: "AdjustBalance",
  service: BalanceService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_balances_pb.AdjustBalanceRequest,
  responseType: backend_v1_balances_pb.Balance
};

exports.BalanceService = BalanceService;

function BalanceServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BalanceServiceClient.prototype.createBalance = function createBalance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.CreateBalance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.getBalance = function getBalance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.GetBalance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.updateBalance = function updateBalance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.UpdateBalance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.deleteBalance = function deleteBalance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.DeleteBalance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.listBalanceEvents = function listBalanceEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.ListBalanceEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.listBalances = function listBalances(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.ListBalances, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.batchGetBalances = function batchGetBalances(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.BatchGetBalances, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BalanceServiceClient.prototype.adjustBalance = function adjustBalance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BalanceService.AdjustBalance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BalanceServiceClient = BalanceServiceClient;

