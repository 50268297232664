// package: proto
// file: backend/v1/stores.proto

var backend_v1_stores_pb = require("../../backend/v1/stores_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var StoreService = (function () {
  function StoreService() {}
  StoreService.serviceName = "proto.StoreService";
  return StoreService;
}());

StoreService.CreateStore = {
  methodName: "CreateStore",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.CreateStoreRequest,
  responseType: backend_v1_stores_pb.Store
};

StoreService.GetStore = {
  methodName: "GetStore",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.GetStoreRequest,
  responseType: backend_v1_stores_pb.Store
};

StoreService.UpdateStore = {
  methodName: "UpdateStore",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.UpdateStoreRequest,
  responseType: backend_v1_stores_pb.Store
};

StoreService.DeleteStore = {
  methodName: "DeleteStore",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.DeleteStoreRequest,
  responseType: google_protobuf_empty_pb.Empty
};

StoreService.ListStoreEvents = {
  methodName: "ListStoreEvents",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.ListStoreEventsRequest,
  responseType: backend_v1_stores_pb.ListStoreEventsResponse
};

StoreService.ListStores = {
  methodName: "ListStores",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.ListStoresRequest,
  responseType: backend_v1_stores_pb.ListStoresResponse
};

StoreService.BatchGetStores = {
  methodName: "BatchGetStores",
  service: StoreService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_stores_pb.BatchGetStoresRequest,
  responseType: backend_v1_stores_pb.BatchGetStoresResponse
};

exports.StoreService = StoreService;

function StoreServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

StoreServiceClient.prototype.createStore = function createStore(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.CreateStore, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

StoreServiceClient.prototype.getStore = function getStore(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.GetStore, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

StoreServiceClient.prototype.updateStore = function updateStore(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.UpdateStore, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

StoreServiceClient.prototype.deleteStore = function deleteStore(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.DeleteStore, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

StoreServiceClient.prototype.listStoreEvents = function listStoreEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.ListStoreEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

StoreServiceClient.prototype.listStores = function listStores(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.ListStores, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

StoreServiceClient.prototype.batchGetStores = function batchGetStores(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(StoreService.BatchGetStores, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.StoreServiceClient = StoreServiceClient;

