// package: proto
// file: backend/v1/reviews.proto

var backend_v1_reviews_pb = require("../../backend/v1/reviews_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ReviewService = (function () {
  function ReviewService() {}
  ReviewService.serviceName = "proto.ReviewService";
  return ReviewService;
}());

ReviewService.CreateReview = {
  methodName: "CreateReview",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.CreateReviewRequest,
  responseType: backend_v1_reviews_pb.Review
};

ReviewService.GetReview = {
  methodName: "GetReview",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.GetReviewRequest,
  responseType: backend_v1_reviews_pb.Review
};

ReviewService.UpdateReview = {
  methodName: "UpdateReview",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.UpdateReviewRequest,
  responseType: backend_v1_reviews_pb.Review
};

ReviewService.DeleteReview = {
  methodName: "DeleteReview",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.DeleteReviewRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ReviewService.ListReviewEvents = {
  methodName: "ListReviewEvents",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.ListReviewEventsRequest,
  responseType: backend_v1_reviews_pb.ListReviewEventsResponse
};

ReviewService.ListReviews = {
  methodName: "ListReviews",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.ListReviewsRequest,
  responseType: backend_v1_reviews_pb.ListReviewsResponse
};

ReviewService.BatchGetReviews = {
  methodName: "BatchGetReviews",
  service: ReviewService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_reviews_pb.BatchGetReviewsRequest,
  responseType: backend_v1_reviews_pb.BatchGetReviewsResponse
};

exports.ReviewService = ReviewService;

function ReviewServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ReviewServiceClient.prototype.createReview = function createReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.CreateReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ReviewServiceClient.prototype.getReview = function getReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.GetReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ReviewServiceClient.prototype.updateReview = function updateReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.UpdateReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ReviewServiceClient.prototype.deleteReview = function deleteReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.DeleteReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ReviewServiceClient.prototype.listReviewEvents = function listReviewEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.ListReviewEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ReviewServiceClient.prototype.listReviews = function listReviews(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.ListReviews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ReviewServiceClient.prototype.batchGetReviews = function batchGetReviews(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ReviewService.BatchGetReviews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ReviewServiceClient = ReviewServiceClient;

