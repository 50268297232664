// package: proto
// file: backend/v1/modifier_groups.proto

var backend_v1_modifier_groups_pb = require("../../backend/v1/modifier_groups_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ModifierGroupService = (function () {
  function ModifierGroupService() {}
  ModifierGroupService.serviceName = "proto.ModifierGroupService";
  return ModifierGroupService;
}());

ModifierGroupService.CreateModifierGroup = {
  methodName: "CreateModifierGroup",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.CreateModifierGroupRequest,
  responseType: backend_v1_modifier_groups_pb.ModifierGroup
};

ModifierGroupService.GetModifierGroup = {
  methodName: "GetModifierGroup",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.GetModifierGroupRequest,
  responseType: backend_v1_modifier_groups_pb.ModifierGroup
};

ModifierGroupService.UpdateModifierGroup = {
  methodName: "UpdateModifierGroup",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.UpdateModifierGroupRequest,
  responseType: backend_v1_modifier_groups_pb.ModifierGroup
};

ModifierGroupService.DeleteModifierGroup = {
  methodName: "DeleteModifierGroup",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.DeleteModifierGroupRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ModifierGroupService.ListModifierGroupEvents = {
  methodName: "ListModifierGroupEvents",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.ListModifierGroupEventsRequest,
  responseType: backend_v1_modifier_groups_pb.ListModifierGroupEventsResponse
};

ModifierGroupService.ListModifierGroups = {
  methodName: "ListModifierGroups",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.ListModifierGroupsRequest,
  responseType: backend_v1_modifier_groups_pb.ListModifierGroupsResponse
};

ModifierGroupService.BatchGetModifierGroups = {
  methodName: "BatchGetModifierGroups",
  service: ModifierGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifier_groups_pb.BatchGetModifierGroupsRequest,
  responseType: backend_v1_modifier_groups_pb.BatchGetModifierGroupsResponse
};

exports.ModifierGroupService = ModifierGroupService;

function ModifierGroupServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ModifierGroupServiceClient.prototype.createModifierGroup = function createModifierGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.CreateModifierGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierGroupServiceClient.prototype.getModifierGroup = function getModifierGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.GetModifierGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierGroupServiceClient.prototype.updateModifierGroup = function updateModifierGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.UpdateModifierGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierGroupServiceClient.prototype.deleteModifierGroup = function deleteModifierGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.DeleteModifierGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierGroupServiceClient.prototype.listModifierGroupEvents = function listModifierGroupEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.ListModifierGroupEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierGroupServiceClient.prototype.listModifierGroups = function listModifierGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.ListModifierGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierGroupServiceClient.prototype.batchGetModifierGroups = function batchGetModifierGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierGroupService.BatchGetModifierGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ModifierGroupServiceClient = ModifierGroupServiceClient;

