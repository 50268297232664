// package: proto
// file: backend/v1/variations.proto

var backend_v1_variations_pb = require("../../backend/v1/variations_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var VariationService = (function () {
  function VariationService() {}
  VariationService.serviceName = "proto.VariationService";
  return VariationService;
}());

VariationService.CreateVariation = {
  methodName: "CreateVariation",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.CreateVariationRequest,
  responseType: backend_v1_variations_pb.Variation
};

VariationService.GetVariation = {
  methodName: "GetVariation",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.GetVariationRequest,
  responseType: backend_v1_variations_pb.Variation
};

VariationService.UpdateVariation = {
  methodName: "UpdateVariation",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.UpdateVariationRequest,
  responseType: backend_v1_variations_pb.Variation
};

VariationService.DeleteVariation = {
  methodName: "DeleteVariation",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.DeleteVariationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

VariationService.ListVariationEvents = {
  methodName: "ListVariationEvents",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.ListVariationEventsRequest,
  responseType: backend_v1_variations_pb.ListVariationEventsResponse
};

VariationService.ListVariations = {
  methodName: "ListVariations",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.ListVariationsRequest,
  responseType: backend_v1_variations_pb.ListVariationsResponse
};

VariationService.BatchGetVariations = {
  methodName: "BatchGetVariations",
  service: VariationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variations_pb.BatchGetVariationsRequest,
  responseType: backend_v1_variations_pb.BatchGetVariationsResponse
};

exports.VariationService = VariationService;

function VariationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

VariationServiceClient.prototype.createVariation = function createVariation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.CreateVariation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationServiceClient.prototype.getVariation = function getVariation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.GetVariation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationServiceClient.prototype.updateVariation = function updateVariation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.UpdateVariation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationServiceClient.prototype.deleteVariation = function deleteVariation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.DeleteVariation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationServiceClient.prototype.listVariationEvents = function listVariationEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.ListVariationEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationServiceClient.prototype.listVariations = function listVariations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.ListVariations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationServiceClient.prototype.batchGetVariations = function batchGetVariations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationService.BatchGetVariations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.VariationServiceClient = VariationServiceClient;

