// package: proto
// file: backend/v1/locations.proto

var backend_v1_locations_pb = require("../../backend/v1/locations_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var LocationService = (function () {
  function LocationService() {}
  LocationService.serviceName = "proto.LocationService";
  return LocationService;
}());

LocationService.CreateLocation = {
  methodName: "CreateLocation",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.CreateLocationRequest,
  responseType: backend_v1_locations_pb.Location
};

LocationService.GetLocation = {
  methodName: "GetLocation",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.GetLocationRequest,
  responseType: backend_v1_locations_pb.Location
};

LocationService.UpdateLocation = {
  methodName: "UpdateLocation",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.UpdateLocationRequest,
  responseType: backend_v1_locations_pb.Location
};

LocationService.DeleteLocation = {
  methodName: "DeleteLocation",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.DeleteLocationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

LocationService.ListLocationEvents = {
  methodName: "ListLocationEvents",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.ListLocationEventsRequest,
  responseType: backend_v1_locations_pb.ListLocationEventsResponse
};

LocationService.ListLocations = {
  methodName: "ListLocations",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.ListLocationsRequest,
  responseType: backend_v1_locations_pb.ListLocationsResponse
};

LocationService.BatchGetLocations = {
  methodName: "BatchGetLocations",
  service: LocationService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_locations_pb.BatchGetLocationsRequest,
  responseType: backend_v1_locations_pb.BatchGetLocationsResponse
};

exports.LocationService = LocationService;

function LocationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

LocationServiceClient.prototype.createLocation = function createLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.CreateLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LocationServiceClient.prototype.getLocation = function getLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.GetLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LocationServiceClient.prototype.updateLocation = function updateLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.UpdateLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LocationServiceClient.prototype.deleteLocation = function deleteLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.DeleteLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LocationServiceClient.prototype.listLocationEvents = function listLocationEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.ListLocationEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LocationServiceClient.prototype.listLocations = function listLocations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.ListLocations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LocationServiceClient.prototype.batchGetLocations = function batchGetLocations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(LocationService.BatchGetLocations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.LocationServiceClient = LocationServiceClient;

