// package: proto
// file: backend/v1/orders.proto

var backend_v1_orders_pb = require("../../backend/v1/orders_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var OrderService = (function () {
  function OrderService() {}
  OrderService.serviceName = "proto.OrderService";
  return OrderService;
}());

OrderService.CreateOrder = {
  methodName: "CreateOrder",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.CreateOrderRequest,
  responseType: backend_v1_orders_pb.Order
};

OrderService.GetOrder = {
  methodName: "GetOrder",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.GetOrderRequest,
  responseType: backend_v1_orders_pb.Order
};

OrderService.UpdateOrder = {
  methodName: "UpdateOrder",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.UpdateOrderRequest,
  responseType: backend_v1_orders_pb.Order
};

OrderService.DeleteOrder = {
  methodName: "DeleteOrder",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.DeleteOrderRequest,
  responseType: google_protobuf_empty_pb.Empty
};

OrderService.ListOrderEvents = {
  methodName: "ListOrderEvents",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.ListOrderEventsRequest,
  responseType: backend_v1_orders_pb.ListOrderEventsResponse
};

OrderService.ListOrders = {
  methodName: "ListOrders",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.ListOrdersRequest,
  responseType: backend_v1_orders_pb.ListOrdersResponse
};

OrderService.BatchGetOrders = {
  methodName: "BatchGetOrders",
  service: OrderService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.BatchGetOrdersRequest,
  responseType: backend_v1_orders_pb.BatchGetOrdersResponse
};

exports.OrderService = OrderService;

function OrderServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

OrderServiceClient.prototype.createOrder = function createOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.CreateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderServiceClient.prototype.getOrder = function getOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.GetOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderServiceClient.prototype.updateOrder = function updateOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.UpdateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderServiceClient.prototype.deleteOrder = function deleteOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.DeleteOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderServiceClient.prototype.listOrderEvents = function listOrderEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.ListOrderEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderServiceClient.prototype.listOrders = function listOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.ListOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrderServiceClient.prototype.batchGetOrders = function batchGetOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrderService.BatchGetOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.OrderServiceClient = OrderServiceClient;

