// package: proto
// file: backend/v1/products.proto

var backend_v1_products_pb = require("../../backend/v1/products_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProductService = (function () {
  function ProductService() {}
  ProductService.serviceName = "proto.ProductService";
  return ProductService;
}());

ProductService.CreateProduct = {
  methodName: "CreateProduct",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.CreateProductRequest,
  responseType: backend_v1_products_pb.Product
};

ProductService.GetProduct = {
  methodName: "GetProduct",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.GetProductRequest,
  responseType: backend_v1_products_pb.Product
};

ProductService.UpdateProduct = {
  methodName: "UpdateProduct",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.UpdateProductRequest,
  responseType: backend_v1_products_pb.Product
};

ProductService.DeleteProduct = {
  methodName: "DeleteProduct",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.DeleteProductRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProductService.ListProductEvents = {
  methodName: "ListProductEvents",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.ListProductEventsRequest,
  responseType: backend_v1_products_pb.ListProductEventsResponse
};

ProductService.ListProducts = {
  methodName: "ListProducts",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.ListProductsRequest,
  responseType: backend_v1_products_pb.ListProductsResponse
};

ProductService.BatchGetProducts = {
  methodName: "BatchGetProducts",
  service: ProductService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_products_pb.BatchGetProductsRequest,
  responseType: backend_v1_products_pb.BatchGetProductsResponse
};

exports.ProductService = ProductService;

function ProductServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProductServiceClient.prototype.createProduct = function createProduct(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.CreateProduct, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductServiceClient.prototype.getProduct = function getProduct(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.GetProduct, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductServiceClient.prototype.updateProduct = function updateProduct(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.UpdateProduct, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductServiceClient.prototype.deleteProduct = function deleteProduct(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.DeleteProduct, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductServiceClient.prototype.listProductEvents = function listProductEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.ListProductEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductServiceClient.prototype.listProducts = function listProducts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.ListProducts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductServiceClient.prototype.batchGetProducts = function batchGetProducts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductService.BatchGetProducts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProductServiceClient = ProductServiceClient;

