// source: backend/v1/stores.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var backend_v1_common_pb = require('../../backend/v1/common_pb.js');
goog.object.extend(proto, backend_v1_common_pb);
var backend_v1_ratings_pb = require('../../backend/v1/ratings_pb.js');
goog.object.extend(proto, backend_v1_ratings_pb);
var backend_v1_hours_pb = require('../../backend/v1/hours_pb.js');
goog.object.extend(proto, backend_v1_hours_pb);
var backend_v1_payments_pb = require('../../backend/v1/payments_pb.js');
goog.object.extend(proto, backend_v1_payments_pb);
var backend_v1_carts_pb = require('../../backend/v1/carts_pb.js');
goog.object.extend(proto, backend_v1_carts_pb);
goog.exportSymbol('proto.proto.BatchGetStoresRequest', null, global);
goog.exportSymbol('proto.proto.BatchGetStoresResponse', null, global);
goog.exportSymbol('proto.proto.CreateStoreRequest', null, global);
goog.exportSymbol('proto.proto.DeleteStoreRequest', null, global);
goog.exportSymbol('proto.proto.GetStoreRequest', null, global);
goog.exportSymbol('proto.proto.ListStoreEventsRequest', null, global);
goog.exportSymbol('proto.proto.ListStoreEventsResponse', null, global);
goog.exportSymbol('proto.proto.ListStoresRequest', null, global);
goog.exportSymbol('proto.proto.ListStoresResponse', null, global);
goog.exportSymbol('proto.proto.Store', null, global);
goog.exportSymbol('proto.proto.StoreApplicationFee', null, global);
goog.exportSymbol('proto.proto.StoreDispatchMethod', null, global);
goog.exportSymbol('proto.proto.StoreEvent', null, global);
goog.exportSymbol('proto.proto.StoreOrderBy', null, global);
goog.exportSymbol('proto.proto.StorePaymentGateway', null, global);
goog.exportSymbol('proto.proto.StoreServiceFee', null, global);
goog.exportSymbol('proto.proto.StoreUpdateMask', null, global);
goog.exportSymbol('proto.proto.UpdateStoreRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateStoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateStoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateStoreRequest.displayName = 'proto.proto.CreateStoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetStoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetStoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetStoreRequest.displayName = 'proto.proto.GetStoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateStoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UpdateStoreRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.UpdateStoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateStoreRequest.displayName = 'proto.proto.UpdateStoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteStoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteStoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteStoreRequest.displayName = 'proto.proto.DeleteStoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListStoreEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListStoreEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListStoreEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListStoreEventsRequest.displayName = 'proto.proto.ListStoreEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListStoreEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListStoreEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListStoreEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListStoreEventsResponse.displayName = 'proto.proto.ListStoreEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListStoresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListStoresRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListStoresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListStoresRequest.displayName = 'proto.proto.ListStoresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListStoresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListStoresResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListStoresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListStoresResponse.displayName = 'proto.proto.ListStoresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetStoresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BatchGetStoresRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.BatchGetStoresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetStoresRequest.displayName = 'proto.proto.BatchGetStoresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetStoresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BatchGetStoresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetStoresResponse.displayName = 'proto.proto.BatchGetStoresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Store = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Store.repeatedFields_, null);
};
goog.inherits(proto.proto.Store, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Store.displayName = 'proto.proto.Store';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StoreDispatchMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StoreDispatchMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StoreDispatchMethod.displayName = 'proto.proto.StoreDispatchMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StorePaymentGateway = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StorePaymentGateway, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StorePaymentGateway.displayName = 'proto.proto.StorePaymentGateway';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StoreServiceFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StoreServiceFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StoreServiceFee.displayName = 'proto.proto.StoreServiceFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StoreApplicationFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StoreApplicationFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StoreApplicationFee.displayName = 'proto.proto.StoreApplicationFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StoreEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.StoreEvent.repeatedFields_, null);
};
goog.inherits(proto.proto.StoreEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StoreEvent.displayName = 'proto.proto.StoreEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateStoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateStoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateStoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateStoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    store: (f = msg.getStore()) && proto.proto.Store.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateStoreRequest}
 */
proto.proto.CreateStoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateStoreRequest;
  return proto.proto.CreateStoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateStoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateStoreRequest}
 */
proto.proto.CreateStoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Store;
      reader.readMessage(value,proto.proto.Store.deserializeBinaryFromReader);
      msg.setStore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateStoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateStoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateStoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateStoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Store.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.CreateStoreRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateStoreRequest} returns this
 */
proto.proto.CreateStoreRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Store store = 2;
 * @return {?proto.proto.Store}
 */
proto.proto.CreateStoreRequest.prototype.getStore = function() {
  return /** @type{?proto.proto.Store} */ (
    jspb.Message.getWrapperField(this, proto.proto.Store, 2));
};


/**
 * @param {?proto.proto.Store|undefined} value
 * @return {!proto.proto.CreateStoreRequest} returns this
*/
proto.proto.CreateStoreRequest.prototype.setStore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateStoreRequest} returns this
 */
proto.proto.CreateStoreRequest.prototype.clearStore = function() {
  return this.setStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateStoreRequest.prototype.hasStore = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetStoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetStoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetStoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetStoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetStoreRequest}
 */
proto.proto.GetStoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetStoreRequest;
  return proto.proto.GetStoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetStoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetStoreRequest}
 */
proto.proto.GetStoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetStoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetStoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetStoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetStoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.GetStoreRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetStoreRequest} returns this
 */
proto.proto.GetStoreRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UpdateStoreRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateStoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateStoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateStoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateStoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    store: (f = msg.getStore()) && proto.proto.Store.toObject(includeInstance, f),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateStoreRequest}
 */
proto.proto.UpdateStoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateStoreRequest;
  return proto.proto.UpdateStoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateStoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateStoreRequest}
 */
proto.proto.UpdateStoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Store;
      reader.readMessage(value,proto.proto.Store.deserializeBinaryFromReader);
      msg.setStore(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.StoreUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateStoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateStoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateStoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateStoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Store.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.UpdateStoreRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateStoreRequest} returns this
 */
proto.proto.UpdateStoreRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Store store = 2;
 * @return {?proto.proto.Store}
 */
proto.proto.UpdateStoreRequest.prototype.getStore = function() {
  return /** @type{?proto.proto.Store} */ (
    jspb.Message.getWrapperField(this, proto.proto.Store, 2));
};


/**
 * @param {?proto.proto.Store|undefined} value
 * @return {!proto.proto.UpdateStoreRequest} returns this
*/
proto.proto.UpdateStoreRequest.prototype.setStore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateStoreRequest} returns this
 */
proto.proto.UpdateStoreRequest.prototype.clearStore = function() {
  return this.setStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateStoreRequest.prototype.hasStore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated StoreUpdateMask update_masks = 3;
 * @return {!Array<!proto.proto.StoreUpdateMask>}
 */
proto.proto.UpdateStoreRequest.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.StoreUpdateMask>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.StoreUpdateMask>} value
 * @return {!proto.proto.UpdateStoreRequest} returns this
 */
proto.proto.UpdateStoreRequest.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.StoreUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.UpdateStoreRequest} returns this
 */
proto.proto.UpdateStoreRequest.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UpdateStoreRequest} returns this
 */
proto.proto.UpdateStoreRequest.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteStoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteStoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteStoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteStoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteStoreRequest}
 */
proto.proto.DeleteStoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteStoreRequest;
  return proto.proto.DeleteStoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteStoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteStoreRequest}
 */
proto.proto.DeleteStoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteStoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteStoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteStoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteStoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.DeleteStoreRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteStoreRequest} returns this
 */
proto.proto.DeleteStoreRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListStoreEventsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListStoreEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListStoreEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListStoreEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoreEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListStoreEventsRequest}
 */
proto.proto.ListStoreEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListStoreEventsRequest;
  return proto.proto.ListStoreEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListStoreEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListStoreEventsRequest}
 */
proto.proto.ListStoreEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.StoreOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListStoreEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListStoreEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListStoreEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoreEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.ListStoreEventsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListStoreEventsRequest} returns this
 */
proto.proto.ListStoreEventsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.ListStoreEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListStoreEventsRequest} returns this
 */
proto.proto.ListStoreEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 offset = 3;
 * @return {number}
 */
proto.proto.ListStoreEventsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListStoreEventsRequest} returns this
 */
proto.proto.ListStoreEventsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated StoreOrderBy order_by = 4;
 * @return {!Array<!proto.proto.StoreOrderBy>}
 */
proto.proto.ListStoreEventsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.StoreOrderBy>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.StoreOrderBy>} value
 * @return {!proto.proto.ListStoreEventsRequest} returns this
 */
proto.proto.ListStoreEventsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.StoreOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListStoreEventsRequest} returns this
 */
proto.proto.ListStoreEventsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListStoreEventsRequest} returns this
 */
proto.proto.ListStoreEventsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListStoreEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListStoreEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListStoreEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListStoreEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoreEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeEventsList: jspb.Message.toObjectList(msg.getStoreEventsList(),
    proto.proto.StoreEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListStoreEventsResponse}
 */
proto.proto.ListStoreEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListStoreEventsResponse;
  return proto.proto.ListStoreEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListStoreEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListStoreEventsResponse}
 */
proto.proto.ListStoreEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StoreEvent;
      reader.readMessage(value,proto.proto.StoreEvent.deserializeBinaryFromReader);
      msg.addStoreEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListStoreEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListStoreEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListStoreEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoreEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.StoreEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StoreEvent store_events = 1;
 * @return {!Array<!proto.proto.StoreEvent>}
 */
proto.proto.ListStoreEventsResponse.prototype.getStoreEventsList = function() {
  return /** @type{!Array<!proto.proto.StoreEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StoreEvent, 1));
};


/**
 * @param {!Array<!proto.proto.StoreEvent>} value
 * @return {!proto.proto.ListStoreEventsResponse} returns this
*/
proto.proto.ListStoreEventsResponse.prototype.setStoreEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.StoreEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StoreEvent}
 */
proto.proto.ListStoreEventsResponse.prototype.addStoreEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.StoreEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListStoreEventsResponse} returns this
 */
proto.proto.ListStoreEventsResponse.prototype.clearStoreEventsList = function() {
  return this.setStoreEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListStoresRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListStoresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListStoresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListStoresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    merchantIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    titleMatch: (f = msg.getTitleMatch()) && backend_v1_common_pb.Match.toObject(includeInstance, f),
    phoneNumberMatch: (f = msg.getPhoneNumberMatch()) && backend_v1_common_pb.Match.toObject(includeInstance, f),
    emailAddressMatch: (f = msg.getEmailAddressMatch()) && backend_v1_common_pb.Match.toObject(includeInstance, f),
    createTimestamp: (f = msg.getCreateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListStoresRequest}
 */
proto.proto.ListStoresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListStoresRequest;
  return proto.proto.ListStoresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListStoresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListStoresRequest}
 */
proto.proto.ListStoresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.StoreOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addMerchantIds(value);
      break;
    case 5:
      var value = new backend_v1_common_pb.Match;
      reader.readMessage(value,backend_v1_common_pb.Match.deserializeBinaryFromReader);
      msg.setTitleMatch(value);
      break;
    case 6:
      var value = new backend_v1_common_pb.Match;
      reader.readMessage(value,backend_v1_common_pb.Match.deserializeBinaryFromReader);
      msg.setPhoneNumberMatch(value);
      break;
    case 7:
      var value = new backend_v1_common_pb.Match;
      reader.readMessage(value,backend_v1_common_pb.Match.deserializeBinaryFromReader);
      msg.setEmailAddressMatch(value);
      break;
    case 8:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 9:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListStoresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListStoresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListStoresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getMerchantIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getTitleMatch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      backend_v1_common_pb.Match.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumberMatch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      backend_v1_common_pb.Match.serializeBinaryToWriter
    );
  }
  f = message.getEmailAddressMatch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      backend_v1_common_pb.Match.serializeBinaryToWriter
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 limit = 1;
 * @return {number}
 */
proto.proto.ListStoresRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 offset = 2;
 * @return {number}
 */
proto.proto.ListStoresRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated StoreOrderBy order_by = 3;
 * @return {!Array<!proto.proto.StoreOrderBy>}
 */
proto.proto.ListStoresRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.StoreOrderBy>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.StoreOrderBy>} value
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.StoreOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};


/**
 * repeated string merchant_ids = 4;
 * @return {!Array<string>}
 */
proto.proto.ListStoresRequest.prototype.getMerchantIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.setMerchantIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.addMerchantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearMerchantIdsList = function() {
  return this.setMerchantIdsList([]);
};


/**
 * optional Match title_match = 5;
 * @return {?proto.proto.Match}
 */
proto.proto.ListStoresRequest.prototype.getTitleMatch = function() {
  return /** @type{?proto.proto.Match} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.Match, 5));
};


/**
 * @param {?proto.proto.Match|undefined} value
 * @return {!proto.proto.ListStoresRequest} returns this
*/
proto.proto.ListStoresRequest.prototype.setTitleMatch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearTitleMatch = function() {
  return this.setTitleMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStoresRequest.prototype.hasTitleMatch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Match phone_number_match = 6;
 * @return {?proto.proto.Match}
 */
proto.proto.ListStoresRequest.prototype.getPhoneNumberMatch = function() {
  return /** @type{?proto.proto.Match} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.Match, 6));
};


/**
 * @param {?proto.proto.Match|undefined} value
 * @return {!proto.proto.ListStoresRequest} returns this
*/
proto.proto.ListStoresRequest.prototype.setPhoneNumberMatch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearPhoneNumberMatch = function() {
  return this.setPhoneNumberMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStoresRequest.prototype.hasPhoneNumberMatch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Match email_address_match = 7;
 * @return {?proto.proto.Match}
 */
proto.proto.ListStoresRequest.prototype.getEmailAddressMatch = function() {
  return /** @type{?proto.proto.Match} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.Match, 7));
};


/**
 * @param {?proto.proto.Match|undefined} value
 * @return {!proto.proto.ListStoresRequest} returns this
*/
proto.proto.ListStoresRequest.prototype.setEmailAddressMatch = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearEmailAddressMatch = function() {
  return this.setEmailAddressMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStoresRequest.prototype.hasEmailAddressMatch = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TimestampRange create_timestamp = 8;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListStoresRequest.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 8));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListStoresRequest} returns this
*/
proto.proto.ListStoresRequest.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStoresRequest.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TimestampRange update_timestamp = 9;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListStoresRequest.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 9));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListStoresRequest} returns this
*/
proto.proto.ListStoresRequest.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStoresRequest} returns this
 */
proto.proto.ListStoresRequest.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStoresRequest.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListStoresResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListStoresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListStoresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListStoresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storesList: jspb.Message.toObjectList(msg.getStoresList(),
    proto.proto.Store.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListStoresResponse}
 */
proto.proto.ListStoresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListStoresResponse;
  return proto.proto.ListStoresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListStoresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListStoresResponse}
 */
proto.proto.ListStoresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Store;
      reader.readMessage(value,proto.proto.Store.deserializeBinaryFromReader);
      msg.addStores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListStoresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListStoresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListStoresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStoresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Store.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Store stores = 1;
 * @return {!Array<!proto.proto.Store>}
 */
proto.proto.ListStoresResponse.prototype.getStoresList = function() {
  return /** @type{!Array<!proto.proto.Store>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Store, 1));
};


/**
 * @param {!Array<!proto.proto.Store>} value
 * @return {!proto.proto.ListStoresResponse} returns this
*/
proto.proto.ListStoresResponse.prototype.setStoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Store=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Store}
 */
proto.proto.ListStoresResponse.prototype.addStores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Store, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListStoresResponse} returns this
 */
proto.proto.ListStoresResponse.prototype.clearStoresList = function() {
  return this.setStoresList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BatchGetStoresRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetStoresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetStoresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetStoresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetStoresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetStoresRequest}
 */
proto.proto.BatchGetStoresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetStoresRequest;
  return proto.proto.BatchGetStoresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetStoresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetStoresRequest}
 */
proto.proto.BatchGetStoresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetStoresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetStoresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetStoresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetStoresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.proto.BatchGetStoresRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.BatchGetStoresRequest} returns this
 */
proto.proto.BatchGetStoresRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.BatchGetStoresRequest} returns this
 */
proto.proto.BatchGetStoresRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BatchGetStoresRequest} returns this
 */
proto.proto.BatchGetStoresRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetStoresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetStoresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetStoresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetStoresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    storesMap: (f = msg.getStoresMap()) ? f.toObject(includeInstance, proto.proto.Store.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetStoresResponse}
 */
proto.proto.BatchGetStoresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetStoresResponse;
  return proto.proto.BatchGetStoresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetStoresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetStoresResponse}
 */
proto.proto.BatchGetStoresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getStoresMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.proto.Store.deserializeBinaryFromReader, "", new proto.proto.Store());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetStoresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetStoresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetStoresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetStoresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoresMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.proto.Store.serializeBinaryToWriter);
  }
};


/**
 * map<string, Store> stores = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.proto.Store>}
 */
proto.proto.BatchGetStoresResponse.prototype.getStoresMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.proto.Store>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.proto.Store));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.proto.BatchGetStoresResponse} returns this
 */
proto.proto.BatchGetStoresResponse.prototype.clearStoresMap = function() {
  this.getStoresMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Store.repeatedFields_ = [9,17,18,19,20,22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Store.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Store.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Store} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Store.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createTimestamp: (f = msg.getCreateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    merchantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 24, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productTagsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    locationLineOne: jspb.Message.getFieldWithDefault(msg, 10, ""),
    locationLineTwo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    locationCity: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locationPostcode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    locationLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    locationLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    printerSerialNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    dispatchMethodsList: jspb.Message.toObjectList(msg.getDispatchMethodsList(),
    proto.proto.StoreDispatchMethod.toObject, includeInstance),
    paymentGatewaysList: jspb.Message.toObjectList(msg.getPaymentGatewaysList(),
    proto.proto.StorePaymentGateway.toObject, includeInstance),
    serviceFeesList: jspb.Message.toObjectList(msg.getServiceFeesList(),
    proto.proto.StoreServiceFee.toObject, includeInstance),
    applicationFeesList: jspb.Message.toObjectList(msg.getApplicationFeesList(),
    proto.proto.StoreApplicationFee.toObject, includeInstance),
    rating: (f = msg.getRating()) && backend_v1_ratings_pb.Rating.toObject(includeInstance, f),
    hoursList: jspb.Message.toObjectList(msg.getHoursList(),
    backend_v1_hours_pb.Hour.toObject, includeInstance),
    productGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 23)) == null ? undefined : f,
    active: jspb.Message.getBooleanFieldWithDefault(msg, 25, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Store}
 */
proto.proto.Store.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Store;
  return proto.proto.Store.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Store} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Store}
 */
proto.proto.Store.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductTags(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLineOne(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLineTwo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationCity(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationPostcode(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocationLatitude(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocationLongitude(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrinterSerialNumber(value);
      break;
    case 17:
      var value = new proto.proto.StoreDispatchMethod;
      reader.readMessage(value,proto.proto.StoreDispatchMethod.deserializeBinaryFromReader);
      msg.addDispatchMethods(value);
      break;
    case 18:
      var value = new proto.proto.StorePaymentGateway;
      reader.readMessage(value,proto.proto.StorePaymentGateway.deserializeBinaryFromReader);
      msg.addPaymentGateways(value);
      break;
    case 19:
      var value = new proto.proto.StoreServiceFee;
      reader.readMessage(value,proto.proto.StoreServiceFee.deserializeBinaryFromReader);
      msg.addServiceFees(value);
      break;
    case 20:
      var value = new proto.proto.StoreApplicationFee;
      reader.readMessage(value,proto.proto.StoreApplicationFee.deserializeBinaryFromReader);
      msg.addApplicationFees(value);
      break;
    case 21:
      var value = new backend_v1_ratings_pb.Rating;
      reader.readMessage(value,backend_v1_ratings_pb.Rating.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    case 22:
      var value = new backend_v1_hours_pb.Hour;
      reader.readMessage(value,backend_v1_hours_pb.Hour.deserializeBinaryFromReader);
      msg.addHours(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductGroupIds(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Store.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Store.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Store} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Store.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getLocationLineOne();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLocationLineTwo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocationCity();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocationPostcode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLocationLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getLocationLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getPrinterSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDispatchMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.proto.StoreDispatchMethod.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.proto.StorePaymentGateway.serializeBinaryToWriter
    );
  }
  f = message.getServiceFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.proto.StoreServiceFee.serializeBinaryToWriter
    );
  }
  f = message.getApplicationFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.proto.StoreApplicationFee.serializeBinaryToWriter
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      backend_v1_ratings_pb.Rating.serializeBinaryToWriter
    );
  }
  f = message.getHoursList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      backend_v1_hours_pb.Hour.serializeBinaryToWriter
    );
  }
  f = message.getProductGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      23,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Store.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp update_timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Store.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Store.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Store.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Store.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string merchant_id = 4;
 * @return {string}
 */
proto.proto.Store.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_id = 5;
 * @return {string}
 */
proto.proto.Store.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image_url = 24;
 * @return {string}
 */
proto.proto.Store.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.proto.Store.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone_number = 7;
 * @return {string}
 */
proto.proto.Store.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email_address = 8;
 * @return {string}
 */
proto.proto.Store.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string product_tags = 9;
 * @return {!Array<string>}
 */
proto.proto.Store.prototype.getProductTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setProductTagsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.addProductTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearProductTagsList = function() {
  return this.setProductTagsList([]);
};


/**
 * optional string location_line_one = 10;
 * @return {string}
 */
proto.proto.Store.prototype.getLocationLineOne = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setLocationLineOne = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string location_line_two = 11;
 * @return {string}
 */
proto.proto.Store.prototype.getLocationLineTwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setLocationLineTwo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string location_city = 12;
 * @return {string}
 */
proto.proto.Store.prototype.getLocationCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setLocationCity = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string location_postcode = 13;
 * @return {string}
 */
proto.proto.Store.prototype.getLocationPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setLocationPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional double location_latitude = 14;
 * @return {number}
 */
proto.proto.Store.prototype.getLocationLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setLocationLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double location_longitude = 15;
 * @return {number}
 */
proto.proto.Store.prototype.getLocationLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setLocationLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional string printer_serial_number = 16;
 * @return {string}
 */
proto.proto.Store.prototype.getPrinterSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setPrinterSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated StoreDispatchMethod dispatch_methods = 17;
 * @return {!Array<!proto.proto.StoreDispatchMethod>}
 */
proto.proto.Store.prototype.getDispatchMethodsList = function() {
  return /** @type{!Array<!proto.proto.StoreDispatchMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StoreDispatchMethod, 17));
};


/**
 * @param {!Array<!proto.proto.StoreDispatchMethod>} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setDispatchMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.proto.StoreDispatchMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StoreDispatchMethod}
 */
proto.proto.Store.prototype.addDispatchMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.proto.StoreDispatchMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearDispatchMethodsList = function() {
  return this.setDispatchMethodsList([]);
};


/**
 * repeated StorePaymentGateway payment_gateways = 18;
 * @return {!Array<!proto.proto.StorePaymentGateway>}
 */
proto.proto.Store.prototype.getPaymentGatewaysList = function() {
  return /** @type{!Array<!proto.proto.StorePaymentGateway>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StorePaymentGateway, 18));
};


/**
 * @param {!Array<!proto.proto.StorePaymentGateway>} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setPaymentGatewaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.proto.StorePaymentGateway=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StorePaymentGateway}
 */
proto.proto.Store.prototype.addPaymentGateways = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.proto.StorePaymentGateway, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearPaymentGatewaysList = function() {
  return this.setPaymentGatewaysList([]);
};


/**
 * repeated StoreServiceFee service_fees = 19;
 * @return {!Array<!proto.proto.StoreServiceFee>}
 */
proto.proto.Store.prototype.getServiceFeesList = function() {
  return /** @type{!Array<!proto.proto.StoreServiceFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StoreServiceFee, 19));
};


/**
 * @param {!Array<!proto.proto.StoreServiceFee>} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setServiceFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.proto.StoreServiceFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StoreServiceFee}
 */
proto.proto.Store.prototype.addServiceFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.proto.StoreServiceFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearServiceFeesList = function() {
  return this.setServiceFeesList([]);
};


/**
 * repeated StoreApplicationFee application_fees = 20;
 * @return {!Array<!proto.proto.StoreApplicationFee>}
 */
proto.proto.Store.prototype.getApplicationFeesList = function() {
  return /** @type{!Array<!proto.proto.StoreApplicationFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StoreApplicationFee, 20));
};


/**
 * @param {!Array<!proto.proto.StoreApplicationFee>} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setApplicationFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.proto.StoreApplicationFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StoreApplicationFee}
 */
proto.proto.Store.prototype.addApplicationFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.proto.StoreApplicationFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearApplicationFeesList = function() {
  return this.setApplicationFeesList([]);
};


/**
 * optional Rating rating = 21;
 * @return {?proto.proto.Rating}
 */
proto.proto.Store.prototype.getRating = function() {
  return /** @type{?proto.proto.Rating} */ (
    jspb.Message.getWrapperField(this, backend_v1_ratings_pb.Rating, 21));
};


/**
 * @param {?proto.proto.Rating|undefined} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Store.prototype.hasRating = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated Hour hours = 22;
 * @return {!Array<!proto.proto.Hour>}
 */
proto.proto.Store.prototype.getHoursList = function() {
  return /** @type{!Array<!proto.proto.Hour>} */ (
    jspb.Message.getRepeatedWrapperField(this, backend_v1_hours_pb.Hour, 22));
};


/**
 * @param {!Array<!proto.proto.Hour>} value
 * @return {!proto.proto.Store} returns this
*/
proto.proto.Store.prototype.setHoursList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.proto.Hour=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Hour}
 */
proto.proto.Store.prototype.addHours = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.proto.Hour, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearHoursList = function() {
  return this.setHoursList([]);
};


/**
 * repeated string product_group_ids = 23;
 * @return {!Array<string>}
 */
proto.proto.Store.prototype.getProductGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 23));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setProductGroupIdsList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.addProductGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.clearProductGroupIdsList = function() {
  return this.setProductGroupIdsList([]);
};


/**
 * optional bool active = 25;
 * @return {boolean}
 */
proto.proto.Store.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Store} returns this
 */
proto.proto.Store.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StoreDispatchMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StoreDispatchMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StoreDispatchMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreDispatchMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    dispatchMethod: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minimumDispatchTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minimumCartSubtotal: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StoreDispatchMethod}
 */
proto.proto.StoreDispatchMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StoreDispatchMethod;
  return proto.proto.StoreDispatchMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StoreDispatchMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StoreDispatchMethod}
 */
proto.proto.StoreDispatchMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.proto.CartDispatchMethod} */ (reader.readEnum());
      msg.setDispatchMethod(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinimumDispatchTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinimumCartSubtotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StoreDispatchMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StoreDispatchMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StoreDispatchMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreDispatchMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDispatchMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMinimumDispatchTime();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMinimumCartSubtotal();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional CartDispatchMethod dispatch_method = 1;
 * @return {!proto.proto.CartDispatchMethod}
 */
proto.proto.StoreDispatchMethod.prototype.getDispatchMethod = function() {
  return /** @type {!proto.proto.CartDispatchMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.proto.CartDispatchMethod} value
 * @return {!proto.proto.StoreDispatchMethod} returns this
 */
proto.proto.StoreDispatchMethod.prototype.setDispatchMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 minimum_dispatch_time = 2;
 * @return {number}
 */
proto.proto.StoreDispatchMethod.prototype.getMinimumDispatchTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreDispatchMethod} returns this
 */
proto.proto.StoreDispatchMethod.prototype.setMinimumDispatchTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 minimum_cart_subtotal = 3;
 * @return {number}
 */
proto.proto.StoreDispatchMethod.prototype.getMinimumCartSubtotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreDispatchMethod} returns this
 */
proto.proto.StoreDispatchMethod.prototype.setMinimumCartSubtotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StorePaymentGateway.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StorePaymentGateway.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StorePaymentGateway} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StorePaymentGateway.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGateway: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minimumCartSubtotal: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StorePaymentGateway}
 */
proto.proto.StorePaymentGateway.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StorePaymentGateway;
  return proto.proto.StorePaymentGateway.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StorePaymentGateway} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StorePaymentGateway}
 */
proto.proto.StorePaymentGateway.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.proto.PaymentGateway} */ (reader.readEnum());
      msg.setPaymentGateway(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinimumCartSubtotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StorePaymentGateway.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StorePaymentGateway.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StorePaymentGateway} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StorePaymentGateway.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGateway();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMinimumCartSubtotal();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional PaymentGateway payment_gateway = 1;
 * @return {!proto.proto.PaymentGateway}
 */
proto.proto.StorePaymentGateway.prototype.getPaymentGateway = function() {
  return /** @type {!proto.proto.PaymentGateway} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.proto.PaymentGateway} value
 * @return {!proto.proto.StorePaymentGateway} returns this
 */
proto.proto.StorePaymentGateway.prototype.setPaymentGateway = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 minimum_cart_subtotal = 2;
 * @return {number}
 */
proto.proto.StorePaymentGateway.prototype.getMinimumCartSubtotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StorePaymentGateway} returns this
 */
proto.proto.StorePaymentGateway.prototype.setMinimumCartSubtotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StoreServiceFee.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StoreServiceFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StoreServiceFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreServiceFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fixedCharge: jspb.Message.getFieldWithDefault(msg, 2, 0),
    percentageCharge: jspb.Message.getFieldWithDefault(msg, 3, 0),
    variableCharge: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StoreServiceFee}
 */
proto.proto.StoreServiceFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StoreServiceFee;
  return proto.proto.StoreServiceFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StoreServiceFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StoreServiceFee}
 */
proto.proto.StoreServiceFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFixedCharge(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPercentageCharge(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVariableCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StoreServiceFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StoreServiceFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StoreServiceFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreServiceFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFixedCharge();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPercentageCharge();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVariableCharge();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.proto.StoreServiceFee.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StoreServiceFee} returns this
 */
proto.proto.StoreServiceFee.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 fixed_charge = 2;
 * @return {number}
 */
proto.proto.StoreServiceFee.prototype.getFixedCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreServiceFee} returns this
 */
proto.proto.StoreServiceFee.prototype.setFixedCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 percentage_charge = 3;
 * @return {number}
 */
proto.proto.StoreServiceFee.prototype.getPercentageCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreServiceFee} returns this
 */
proto.proto.StoreServiceFee.prototype.setPercentageCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 variable_charge = 4;
 * @return {number}
 */
proto.proto.StoreServiceFee.prototype.getVariableCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreServiceFee} returns this
 */
proto.proto.StoreServiceFee.prototype.setVariableCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StoreApplicationFee.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StoreApplicationFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StoreApplicationFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreApplicationFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fixedCharge: jspb.Message.getFieldWithDefault(msg, 2, 0),
    percentageCharge: jspb.Message.getFieldWithDefault(msg, 3, 0),
    variableCharge: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyCharge: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StoreApplicationFee}
 */
proto.proto.StoreApplicationFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StoreApplicationFee;
  return proto.proto.StoreApplicationFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StoreApplicationFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StoreApplicationFee}
 */
proto.proto.StoreApplicationFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFixedCharge(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPercentageCharge(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVariableCharge(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMonthlyCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StoreApplicationFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StoreApplicationFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StoreApplicationFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreApplicationFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFixedCharge();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPercentageCharge();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVariableCharge();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMonthlyCharge();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.proto.StoreApplicationFee.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StoreApplicationFee} returns this
 */
proto.proto.StoreApplicationFee.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 fixed_charge = 2;
 * @return {number}
 */
proto.proto.StoreApplicationFee.prototype.getFixedCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreApplicationFee} returns this
 */
proto.proto.StoreApplicationFee.prototype.setFixedCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 percentage_charge = 3;
 * @return {number}
 */
proto.proto.StoreApplicationFee.prototype.getPercentageCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreApplicationFee} returns this
 */
proto.proto.StoreApplicationFee.prototype.setPercentageCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 variable_charge = 4;
 * @return {number}
 */
proto.proto.StoreApplicationFee.prototype.getVariableCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreApplicationFee} returns this
 */
proto.proto.StoreApplicationFee.prototype.setVariableCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 monthly_charge = 5;
 * @return {number}
 */
proto.proto.StoreApplicationFee.prototype.getMonthlyCharge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreApplicationFee} returns this
 */
proto.proto.StoreApplicationFee.prototype.setMonthlyCharge = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.StoreEvent.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StoreEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StoreEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StoreEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    store: (f = msg.getStore()) && proto.proto.Store.toObject(includeInstance, f),
    changeNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StoreEvent}
 */
proto.proto.StoreEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StoreEvent;
  return proto.proto.StoreEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StoreEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StoreEvent}
 */
proto.proto.StoreEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Store;
      reader.readMessage(value,proto.proto.Store.deserializeBinaryFromReader);
      msg.setStore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChangeNumber(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.StoreUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    case 5:
      var value = /** @type {!proto.proto.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StoreEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StoreEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StoreEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StoreEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Store.serializeBinaryToWriter
    );
  }
  f = message.getChangeNumber();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.StoreEvent.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Store store = 2;
 * @return {?proto.proto.Store}
 */
proto.proto.StoreEvent.prototype.getStore = function() {
  return /** @type{?proto.proto.Store} */ (
    jspb.Message.getWrapperField(this, proto.proto.Store, 2));
};


/**
 * @param {?proto.proto.Store|undefined} value
 * @return {!proto.proto.StoreEvent} returns this
*/
proto.proto.StoreEvent.prototype.setStore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.clearStore = function() {
  return this.setStore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StoreEvent.prototype.hasStore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 change_number = 3;
 * @return {number}
 */
proto.proto.StoreEvent.prototype.getChangeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.setChangeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated StoreUpdateMask update_masks = 4;
 * @return {!Array<!proto.proto.StoreUpdateMask>}
 */
proto.proto.StoreEvent.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.StoreUpdateMask>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.StoreUpdateMask>} value
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.StoreUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};


/**
 * optional EventType event_type = 5;
 * @return {!proto.proto.EventType}
 */
proto.proto.StoreEvent.prototype.getEventType = function() {
  return /** @type {!proto.proto.EventType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.EventType} value
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool processed = 6;
 * @return {boolean}
 */
proto.proto.StoreEvent.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.StoreEvent} returns this
 */
proto.proto.StoreEvent.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.proto.StoreUpdateMask = {
  STORE_UPDATE_MASK_UNKNOWN: 0,
  STORE_UPDATE_MASK_IMAGE_ID: 1,
  STORE_UPDATE_MASK_TITLE: 2,
  STORE_UPDATE_MASK_PHONE_NUMBER: 3,
  STORE_UPDATE_MASK_EMAIL_ADDRESS: 4,
  STORE_UPDATE_MASK_PRODUCT_TAGS: 5,
  STORE_UPDATE_MASK_LOCATION_LINE_ONE: 6,
  STORE_UPDATE_MASK_LOCATION_LINE_TWO: 7,
  STORE_UPDATE_MASK_LOCATION_CITY: 8,
  STORE_UPDATE_MASK_LOCATION_POSTCODE: 9,
  STORE_UPDATE_MASK_LOCATION_LATITUDE: 10,
  STORE_UPDATE_MASK_LOCATION_LONGITUDE: 11,
  STORE_UPDATE_MASK_PRINTER_SERIAL_NUMBER: 12,
  STORE_UPDATE_MASK_DISPATCH_METHODS: 13,
  STORE_UPDATE_MASK_PAYMENT_GATEWAYS: 14,
  STORE_UPDATE_MASK_SERVICE_FEES: 15,
  STORE_UPDATE_MASK_APPLICATION_FEES: 16,
  STORE_UPDATE_MASK_ACTIVE: 17
};

/**
 * @enum {number}
 */
proto.proto.StoreOrderBy = {
  STORE_ORDER_BY_UNKNOWN: 0,
  STORE_ORDER_BY_UPDATE_TIMESTAMP_ASC: 1,
  STORE_ORDER_BY_UPDATE_TIMESTAMP_DESC: 2,
  STORE_ORDER_BY_CREATE_TIMESTAMP_ASC: 3,
  STORE_ORDER_BY_CREATE_TIMESTAMP_DESC: 4
};

goog.object.extend(exports, proto.proto);
