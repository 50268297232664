// package: proto
// file: backend/v1/variation_links.proto

var backend_v1_variation_links_pb = require("../../backend/v1/variation_links_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var VariationLinkService = (function () {
  function VariationLinkService() {}
  VariationLinkService.serviceName = "proto.VariationLinkService";
  return VariationLinkService;
}());

VariationLinkService.CreateVariationLink = {
  methodName: "CreateVariationLink",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.CreateVariationLinkRequest,
  responseType: backend_v1_variation_links_pb.VariationLink
};

VariationLinkService.GetVariationLink = {
  methodName: "GetVariationLink",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.GetVariationLinkRequest,
  responseType: backend_v1_variation_links_pb.VariationLink
};

VariationLinkService.UpdateVariationLink = {
  methodName: "UpdateVariationLink",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.UpdateVariationLinkRequest,
  responseType: backend_v1_variation_links_pb.VariationLink
};

VariationLinkService.DeleteVariationLink = {
  methodName: "DeleteVariationLink",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.DeleteVariationLinkRequest,
  responseType: google_protobuf_empty_pb.Empty
};

VariationLinkService.ListVariationLinkEvents = {
  methodName: "ListVariationLinkEvents",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.ListVariationLinkEventsRequest,
  responseType: backend_v1_variation_links_pb.ListVariationLinkEventsResponse
};

VariationLinkService.ListVariationLinks = {
  methodName: "ListVariationLinks",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.ListVariationLinksRequest,
  responseType: backend_v1_variation_links_pb.ListVariationLinksResponse
};

VariationLinkService.BatchGetVariationLinks = {
  methodName: "BatchGetVariationLinks",
  service: VariationLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_variation_links_pb.BatchGetVariationLinksRequest,
  responseType: backend_v1_variation_links_pb.BatchGetVariationLinksResponse
};

exports.VariationLinkService = VariationLinkService;

function VariationLinkServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

VariationLinkServiceClient.prototype.createVariationLink = function createVariationLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.CreateVariationLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationLinkServiceClient.prototype.getVariationLink = function getVariationLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.GetVariationLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationLinkServiceClient.prototype.updateVariationLink = function updateVariationLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.UpdateVariationLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationLinkServiceClient.prototype.deleteVariationLink = function deleteVariationLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.DeleteVariationLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationLinkServiceClient.prototype.listVariationLinkEvents = function listVariationLinkEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.ListVariationLinkEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationLinkServiceClient.prototype.listVariationLinks = function listVariationLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.ListVariationLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

VariationLinkServiceClient.prototype.batchGetVariationLinks = function batchGetVariationLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(VariationLinkService.BatchGetVariationLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.VariationLinkServiceClient = VariationLinkServiceClient;

