// package: proto
// file: backend/v1/tokens.proto

var backend_v1_tokens_pb = require("../../backend/v1/tokens_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TokenService = (function () {
  function TokenService() {}
  TokenService.serviceName = "proto.TokenService";
  return TokenService;
}());

TokenService.CreateToken = {
  methodName: "CreateToken",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.CreateTokenRequest,
  responseType: backend_v1_tokens_pb.Token
};

TokenService.GetToken = {
  methodName: "GetToken",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.GetTokenRequest,
  responseType: backend_v1_tokens_pb.Token
};

TokenService.UpdateToken = {
  methodName: "UpdateToken",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.UpdateTokenRequest,
  responseType: backend_v1_tokens_pb.Token
};

TokenService.DeleteToken = {
  methodName: "DeleteToken",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.DeleteTokenRequest,
  responseType: google_protobuf_empty_pb.Empty
};

TokenService.ListTokenEvents = {
  methodName: "ListTokenEvents",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.ListTokenEventsRequest,
  responseType: backend_v1_tokens_pb.ListTokenEventsResponse
};

TokenService.ListTokens = {
  methodName: "ListTokens",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.ListTokensRequest,
  responseType: backend_v1_tokens_pb.ListTokensResponse
};

TokenService.BatchGetTokens = {
  methodName: "BatchGetTokens",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.BatchGetTokensRequest,
  responseType: backend_v1_tokens_pb.BatchGetTokensResponse
};

TokenService.ValidateToken = {
  methodName: "ValidateToken",
  service: TokenService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_tokens_pb.ValidateTokenRequest,
  responseType: backend_v1_tokens_pb.Token
};

exports.TokenService = TokenService;

function TokenServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TokenServiceClient.prototype.createToken = function createToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.CreateToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.getToken = function getToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.GetToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.updateToken = function updateToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.UpdateToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.deleteToken = function deleteToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.DeleteToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.listTokenEvents = function listTokenEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.ListTokenEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.listTokens = function listTokens(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.ListTokens, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.batchGetTokens = function batchGetTokens(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.BatchGetTokens, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TokenServiceClient.prototype.validateToken = function validateToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TokenService.ValidateToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TokenServiceClient = TokenServiceClient;

