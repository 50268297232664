// package: proto
// file: backend/v1/modifiers.proto

var backend_v1_modifiers_pb = require("../../backend/v1/modifiers_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ModifierService = (function () {
  function ModifierService() {}
  ModifierService.serviceName = "proto.ModifierService";
  return ModifierService;
}());

ModifierService.CreateModifier = {
  methodName: "CreateModifier",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.CreateModifierRequest,
  responseType: backend_v1_modifiers_pb.Modifier
};

ModifierService.GetModifier = {
  methodName: "GetModifier",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.GetModifierRequest,
  responseType: backend_v1_modifiers_pb.Modifier
};

ModifierService.UpdateModifier = {
  methodName: "UpdateModifier",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.UpdateModifierRequest,
  responseType: backend_v1_modifiers_pb.Modifier
};

ModifierService.DeleteModifier = {
  methodName: "DeleteModifier",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.DeleteModifierRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ModifierService.ListModifierEvents = {
  methodName: "ListModifierEvents",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.ListModifierEventsRequest,
  responseType: backend_v1_modifiers_pb.ListModifierEventsResponse
};

ModifierService.ListModifiers = {
  methodName: "ListModifiers",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.ListModifiersRequest,
  responseType: backend_v1_modifiers_pb.ListModifiersResponse
};

ModifierService.BatchGetModifiers = {
  methodName: "BatchGetModifiers",
  service: ModifierService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_modifiers_pb.BatchGetModifiersRequest,
  responseType: backend_v1_modifiers_pb.BatchGetModifiersResponse
};

exports.ModifierService = ModifierService;

function ModifierServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ModifierServiceClient.prototype.createModifier = function createModifier(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.CreateModifier, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierServiceClient.prototype.getModifier = function getModifier(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.GetModifier, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierServiceClient.prototype.updateModifier = function updateModifier(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.UpdateModifier, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierServiceClient.prototype.deleteModifier = function deleteModifier(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.DeleteModifier, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierServiceClient.prototype.listModifierEvents = function listModifierEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.ListModifierEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierServiceClient.prototype.listModifiers = function listModifiers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.ListModifiers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ModifierServiceClient.prototype.batchGetModifiers = function batchGetModifiers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ModifierService.BatchGetModifiers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ModifierServiceClient = ModifierServiceClient;

