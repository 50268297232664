// source: backend/v1/merchants.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var backend_v1_common_pb = require('../../backend/v1/common_pb.js');
goog.object.extend(proto, backend_v1_common_pb);
var backend_v1_stores_pb = require('../../backend/v1/stores_pb.js');
goog.object.extend(proto, backend_v1_stores_pb);
var backend_v1_products_pb = require('../../backend/v1/products_pb.js');
goog.object.extend(proto, backend_v1_products_pb);
var backend_v1_product_groups_pb = require('../../backend/v1/product_groups_pb.js');
goog.object.extend(proto, backend_v1_product_groups_pb);
var backend_v1_modifier_groups_pb = require('../../backend/v1/modifier_groups_pb.js');
goog.object.extend(proto, backend_v1_modifier_groups_pb);
goog.exportSymbol('proto.proto.BatchGetMerchantsRequest', null, global);
goog.exportSymbol('proto.proto.BatchGetMerchantsResponse', null, global);
goog.exportSymbol('proto.proto.CreateMerchantRequest', null, global);
goog.exportSymbol('proto.proto.DeleteMerchantRequest', null, global);
goog.exportSymbol('proto.proto.GetMerchantRequest', null, global);
goog.exportSymbol('proto.proto.ListMerchantEventsRequest', null, global);
goog.exportSymbol('proto.proto.ListMerchantEventsResponse', null, global);
goog.exportSymbol('proto.proto.ListMerchantsRequest', null, global);
goog.exportSymbol('proto.proto.ListMerchantsResponse', null, global);
goog.exportSymbol('proto.proto.Merchant', null, global);
goog.exportSymbol('proto.proto.MerchantEvent', null, global);
goog.exportSymbol('proto.proto.MerchantOrderBy', null, global);
goog.exportSymbol('proto.proto.MerchantUpdateMask', null, global);
goog.exportSymbol('proto.proto.UpdateMerchantRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateMerchantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateMerchantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateMerchantRequest.displayName = 'proto.proto.CreateMerchantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetMerchantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetMerchantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetMerchantRequest.displayName = 'proto.proto.GetMerchantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateMerchantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UpdateMerchantRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.UpdateMerchantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateMerchantRequest.displayName = 'proto.proto.UpdateMerchantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteMerchantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteMerchantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteMerchantRequest.displayName = 'proto.proto.DeleteMerchantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMerchantEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMerchantEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMerchantEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMerchantEventsRequest.displayName = 'proto.proto.ListMerchantEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMerchantEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMerchantEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMerchantEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMerchantEventsResponse.displayName = 'proto.proto.ListMerchantEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMerchantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMerchantsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMerchantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMerchantsRequest.displayName = 'proto.proto.ListMerchantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMerchantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMerchantsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMerchantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMerchantsResponse.displayName = 'proto.proto.ListMerchantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetMerchantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BatchGetMerchantsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.BatchGetMerchantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetMerchantsRequest.displayName = 'proto.proto.BatchGetMerchantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchGetMerchantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BatchGetMerchantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchGetMerchantsResponse.displayName = 'proto.proto.BatchGetMerchantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Merchant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Merchant.repeatedFields_, null);
};
goog.inherits(proto.proto.Merchant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Merchant.displayName = 'proto.proto.Merchant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MerchantEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MerchantEvent.repeatedFields_, null);
};
goog.inherits(proto.proto.MerchantEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MerchantEvent.displayName = 'proto.proto.MerchantEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateMerchantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateMerchantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateMerchantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateMerchantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    merchant: (f = msg.getMerchant()) && proto.proto.Merchant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateMerchantRequest}
 */
proto.proto.CreateMerchantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateMerchantRequest;
  return proto.proto.CreateMerchantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateMerchantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateMerchantRequest}
 */
proto.proto.CreateMerchantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Merchant;
      reader.readMessage(value,proto.proto.Merchant.deserializeBinaryFromReader);
      msg.setMerchant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateMerchantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateMerchantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateMerchantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateMerchantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMerchant();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Merchant.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.CreateMerchantRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateMerchantRequest} returns this
 */
proto.proto.CreateMerchantRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Merchant merchant = 2;
 * @return {?proto.proto.Merchant}
 */
proto.proto.CreateMerchantRequest.prototype.getMerchant = function() {
  return /** @type{?proto.proto.Merchant} */ (
    jspb.Message.getWrapperField(this, proto.proto.Merchant, 2));
};


/**
 * @param {?proto.proto.Merchant|undefined} value
 * @return {!proto.proto.CreateMerchantRequest} returns this
*/
proto.proto.CreateMerchantRequest.prototype.setMerchant = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateMerchantRequest} returns this
 */
proto.proto.CreateMerchantRequest.prototype.clearMerchant = function() {
  return this.setMerchant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateMerchantRequest.prototype.hasMerchant = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetMerchantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetMerchantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetMerchantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetMerchantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetMerchantRequest}
 */
proto.proto.GetMerchantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetMerchantRequest;
  return proto.proto.GetMerchantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetMerchantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetMerchantRequest}
 */
proto.proto.GetMerchantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetMerchantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetMerchantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetMerchantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetMerchantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.GetMerchantRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetMerchantRequest} returns this
 */
proto.proto.GetMerchantRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UpdateMerchantRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateMerchantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateMerchantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateMerchantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateMerchantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    merchant: (f = msg.getMerchant()) && proto.proto.Merchant.toObject(includeInstance, f),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateMerchantRequest}
 */
proto.proto.UpdateMerchantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateMerchantRequest;
  return proto.proto.UpdateMerchantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateMerchantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateMerchantRequest}
 */
proto.proto.UpdateMerchantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Merchant;
      reader.readMessage(value,proto.proto.Merchant.deserializeBinaryFromReader);
      msg.setMerchant(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.MerchantUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateMerchantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateMerchantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateMerchantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateMerchantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMerchant();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Merchant.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.UpdateMerchantRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateMerchantRequest} returns this
 */
proto.proto.UpdateMerchantRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Merchant merchant = 2;
 * @return {?proto.proto.Merchant}
 */
proto.proto.UpdateMerchantRequest.prototype.getMerchant = function() {
  return /** @type{?proto.proto.Merchant} */ (
    jspb.Message.getWrapperField(this, proto.proto.Merchant, 2));
};


/**
 * @param {?proto.proto.Merchant|undefined} value
 * @return {!proto.proto.UpdateMerchantRequest} returns this
*/
proto.proto.UpdateMerchantRequest.prototype.setMerchant = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateMerchantRequest} returns this
 */
proto.proto.UpdateMerchantRequest.prototype.clearMerchant = function() {
  return this.setMerchant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateMerchantRequest.prototype.hasMerchant = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated MerchantUpdateMask update_masks = 3;
 * @return {!Array<!proto.proto.MerchantUpdateMask>}
 */
proto.proto.UpdateMerchantRequest.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.MerchantUpdateMask>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.MerchantUpdateMask>} value
 * @return {!proto.proto.UpdateMerchantRequest} returns this
 */
proto.proto.UpdateMerchantRequest.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.MerchantUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.UpdateMerchantRequest} returns this
 */
proto.proto.UpdateMerchantRequest.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UpdateMerchantRequest} returns this
 */
proto.proto.UpdateMerchantRequest.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteMerchantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteMerchantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteMerchantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteMerchantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteMerchantRequest}
 */
proto.proto.DeleteMerchantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteMerchantRequest;
  return proto.proto.DeleteMerchantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteMerchantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteMerchantRequest}
 */
proto.proto.DeleteMerchantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteMerchantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteMerchantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteMerchantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteMerchantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.DeleteMerchantRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteMerchantRequest} returns this
 */
proto.proto.DeleteMerchantRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMerchantEventsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMerchantEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMerchantEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMerchantEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMerchantEventsRequest}
 */
proto.proto.ListMerchantEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMerchantEventsRequest;
  return proto.proto.ListMerchantEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMerchantEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMerchantEventsRequest}
 */
proto.proto.ListMerchantEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.MerchantOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMerchantEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMerchantEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMerchantEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.ListMerchantEventsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListMerchantEventsRequest} returns this
 */
proto.proto.ListMerchantEventsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.ListMerchantEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMerchantEventsRequest} returns this
 */
proto.proto.ListMerchantEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 offset = 3;
 * @return {number}
 */
proto.proto.ListMerchantEventsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMerchantEventsRequest} returns this
 */
proto.proto.ListMerchantEventsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated MerchantOrderBy order_by = 4;
 * @return {!Array<!proto.proto.MerchantOrderBy>}
 */
proto.proto.ListMerchantEventsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.MerchantOrderBy>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.MerchantOrderBy>} value
 * @return {!proto.proto.ListMerchantEventsRequest} returns this
 */
proto.proto.ListMerchantEventsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.MerchantOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListMerchantEventsRequest} returns this
 */
proto.proto.ListMerchantEventsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMerchantEventsRequest} returns this
 */
proto.proto.ListMerchantEventsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMerchantEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMerchantEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMerchantEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMerchantEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantEventsList: jspb.Message.toObjectList(msg.getMerchantEventsList(),
    proto.proto.MerchantEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMerchantEventsResponse}
 */
proto.proto.ListMerchantEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMerchantEventsResponse;
  return proto.proto.ListMerchantEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMerchantEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMerchantEventsResponse}
 */
proto.proto.ListMerchantEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MerchantEvent;
      reader.readMessage(value,proto.proto.MerchantEvent.deserializeBinaryFromReader);
      msg.addMerchantEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMerchantEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMerchantEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMerchantEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerchantEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MerchantEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MerchantEvent merchant_events = 1;
 * @return {!Array<!proto.proto.MerchantEvent>}
 */
proto.proto.ListMerchantEventsResponse.prototype.getMerchantEventsList = function() {
  return /** @type{!Array<!proto.proto.MerchantEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MerchantEvent, 1));
};


/**
 * @param {!Array<!proto.proto.MerchantEvent>} value
 * @return {!proto.proto.ListMerchantEventsResponse} returns this
*/
proto.proto.ListMerchantEventsResponse.prototype.setMerchantEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MerchantEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MerchantEvent}
 */
proto.proto.ListMerchantEventsResponse.prototype.addMerchantEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MerchantEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMerchantEventsResponse} returns this
 */
proto.proto.ListMerchantEventsResponse.prototype.clearMerchantEventsList = function() {
  return this.setMerchantEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMerchantsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMerchantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMerchantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMerchantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderByList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    titleMatch: (f = msg.getTitleMatch()) && backend_v1_common_pb.Match.toObject(includeInstance, f),
    phoneNumberMatch: (f = msg.getPhoneNumberMatch()) && backend_v1_common_pb.Match.toObject(includeInstance, f),
    emailAddressMatch: (f = msg.getEmailAddressMatch()) && backend_v1_common_pb.Match.toObject(includeInstance, f),
    createTimestamp: (f = msg.getCreateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && backend_v1_common_pb.TimestampRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMerchantsRequest}
 */
proto.proto.ListMerchantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMerchantsRequest;
  return proto.proto.ListMerchantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMerchantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMerchantsRequest}
 */
proto.proto.ListMerchantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.MerchantOrderBy>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderBy(values[i]);
      }
      break;
    case 4:
      var value = new backend_v1_common_pb.Match;
      reader.readMessage(value,backend_v1_common_pb.Match.deserializeBinaryFromReader);
      msg.setTitleMatch(value);
      break;
    case 5:
      var value = new backend_v1_common_pb.Match;
      reader.readMessage(value,backend_v1_common_pb.Match.deserializeBinaryFromReader);
      msg.setPhoneNumberMatch(value);
      break;
    case 6:
      var value = new backend_v1_common_pb.Match;
      reader.readMessage(value,backend_v1_common_pb.Match.deserializeBinaryFromReader);
      msg.setEmailAddressMatch(value);
      break;
    case 7:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 8:
      var value = new backend_v1_common_pb.TimestampRange;
      reader.readMessage(value,backend_v1_common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMerchantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMerchantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMerchantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getTitleMatch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      backend_v1_common_pb.Match.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumberMatch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      backend_v1_common_pb.Match.serializeBinaryToWriter
    );
  }
  f = message.getEmailAddressMatch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      backend_v1_common_pb.Match.serializeBinaryToWriter
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      backend_v1_common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 limit = 1;
 * @return {number}
 */
proto.proto.ListMerchantsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 offset = 2;
 * @return {number}
 */
proto.proto.ListMerchantsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MerchantOrderBy order_by = 3;
 * @return {!Array<!proto.proto.MerchantOrderBy>}
 */
proto.proto.ListMerchantsRequest.prototype.getOrderByList = function() {
  return /** @type {!Array<!proto.proto.MerchantOrderBy>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.MerchantOrderBy>} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.setOrderByList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.MerchantOrderBy} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.addOrderBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};


/**
 * optional Match title_match = 4;
 * @return {?proto.proto.Match}
 */
proto.proto.ListMerchantsRequest.prototype.getTitleMatch = function() {
  return /** @type{?proto.proto.Match} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.Match, 4));
};


/**
 * @param {?proto.proto.Match|undefined} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
*/
proto.proto.ListMerchantsRequest.prototype.setTitleMatch = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.clearTitleMatch = function() {
  return this.setTitleMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMerchantsRequest.prototype.hasTitleMatch = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Match phone_number_match = 5;
 * @return {?proto.proto.Match}
 */
proto.proto.ListMerchantsRequest.prototype.getPhoneNumberMatch = function() {
  return /** @type{?proto.proto.Match} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.Match, 5));
};


/**
 * @param {?proto.proto.Match|undefined} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
*/
proto.proto.ListMerchantsRequest.prototype.setPhoneNumberMatch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.clearPhoneNumberMatch = function() {
  return this.setPhoneNumberMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMerchantsRequest.prototype.hasPhoneNumberMatch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Match email_address_match = 6;
 * @return {?proto.proto.Match}
 */
proto.proto.ListMerchantsRequest.prototype.getEmailAddressMatch = function() {
  return /** @type{?proto.proto.Match} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.Match, 6));
};


/**
 * @param {?proto.proto.Match|undefined} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
*/
proto.proto.ListMerchantsRequest.prototype.setEmailAddressMatch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.clearEmailAddressMatch = function() {
  return this.setEmailAddressMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMerchantsRequest.prototype.hasEmailAddressMatch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TimestampRange create_timestamp = 7;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListMerchantsRequest.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 7));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
*/
proto.proto.ListMerchantsRequest.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMerchantsRequest.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TimestampRange update_timestamp = 8;
 * @return {?proto.proto.TimestampRange}
 */
proto.proto.ListMerchantsRequest.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.proto.TimestampRange} */ (
    jspb.Message.getWrapperField(this, backend_v1_common_pb.TimestampRange, 8));
};


/**
 * @param {?proto.proto.TimestampRange|undefined} value
 * @return {!proto.proto.ListMerchantsRequest} returns this
*/
proto.proto.ListMerchantsRequest.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMerchantsRequest} returns this
 */
proto.proto.ListMerchantsRequest.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMerchantsRequest.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMerchantsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMerchantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMerchantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMerchantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantsList: jspb.Message.toObjectList(msg.getMerchantsList(),
    proto.proto.Merchant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMerchantsResponse}
 */
proto.proto.ListMerchantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMerchantsResponse;
  return proto.proto.ListMerchantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMerchantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMerchantsResponse}
 */
proto.proto.ListMerchantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Merchant;
      reader.readMessage(value,proto.proto.Merchant.deserializeBinaryFromReader);
      msg.addMerchants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMerchantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMerchantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMerchantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMerchantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerchantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Merchant.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Merchant merchants = 1;
 * @return {!Array<!proto.proto.Merchant>}
 */
proto.proto.ListMerchantsResponse.prototype.getMerchantsList = function() {
  return /** @type{!Array<!proto.proto.Merchant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Merchant, 1));
};


/**
 * @param {!Array<!proto.proto.Merchant>} value
 * @return {!proto.proto.ListMerchantsResponse} returns this
*/
proto.proto.ListMerchantsResponse.prototype.setMerchantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Merchant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Merchant}
 */
proto.proto.ListMerchantsResponse.prototype.addMerchants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Merchant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMerchantsResponse} returns this
 */
proto.proto.ListMerchantsResponse.prototype.clearMerchantsList = function() {
  return this.setMerchantsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BatchGetMerchantsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetMerchantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetMerchantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetMerchantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetMerchantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetMerchantsRequest}
 */
proto.proto.BatchGetMerchantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetMerchantsRequest;
  return proto.proto.BatchGetMerchantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetMerchantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetMerchantsRequest}
 */
proto.proto.BatchGetMerchantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetMerchantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetMerchantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetMerchantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetMerchantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.proto.BatchGetMerchantsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.BatchGetMerchantsRequest} returns this
 */
proto.proto.BatchGetMerchantsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.BatchGetMerchantsRequest} returns this
 */
proto.proto.BatchGetMerchantsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BatchGetMerchantsRequest} returns this
 */
proto.proto.BatchGetMerchantsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchGetMerchantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchGetMerchantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchGetMerchantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetMerchantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantsMap: (f = msg.getMerchantsMap()) ? f.toObject(includeInstance, proto.proto.Merchant.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchGetMerchantsResponse}
 */
proto.proto.BatchGetMerchantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchGetMerchantsResponse;
  return proto.proto.BatchGetMerchantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchGetMerchantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchGetMerchantsResponse}
 */
proto.proto.BatchGetMerchantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getMerchantsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.proto.Merchant.deserializeBinaryFromReader, "", new proto.proto.Merchant());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchGetMerchantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchGetMerchantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchGetMerchantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchGetMerchantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerchantsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.proto.Merchant.serializeBinaryToWriter);
  }
};


/**
 * map<string, Merchant> merchants = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.proto.Merchant>}
 */
proto.proto.BatchGetMerchantsResponse.prototype.getMerchantsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.proto.Merchant>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.proto.Merchant));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.proto.BatchGetMerchantsResponse} returns this
 */
proto.proto.BatchGetMerchantsResponse.prototype.clearMerchantsMap = function() {
  this.getMerchantsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Merchant.repeatedFields_ = [9,11,10,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Merchant.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Merchant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Merchant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Merchant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateTimestamp: (f = msg.getUpdateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createTimestamp: (f = msg.getCreateTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 6, ""),
    denomination: jspb.Message.getFieldWithDefault(msg, 7, ""),
    denominationSymbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    storesList: jspb.Message.toObjectList(msg.getStoresList(),
    backend_v1_stores_pb.Store.toObject, includeInstance),
    productGroupsList: jspb.Message.toObjectList(msg.getProductGroupsList(),
    backend_v1_product_groups_pb.ProductGroup.toObject, includeInstance),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    backend_v1_products_pb.Product.toObject, includeInstance),
    modifierGroupsList: jspb.Message.toObjectList(msg.getModifierGroupsList(),
    backend_v1_modifier_groups_pb.ModifierGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Merchant}
 */
proto.proto.Merchant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Merchant;
  return proto.proto.Merchant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Merchant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Merchant}
 */
proto.proto.Merchant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTimestamp(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDenomination(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDenominationSymbol(value);
      break;
    case 9:
      var value = new backend_v1_stores_pb.Store;
      reader.readMessage(value,backend_v1_stores_pb.Store.deserializeBinaryFromReader);
      msg.addStores(value);
      break;
    case 11:
      var value = new backend_v1_product_groups_pb.ProductGroup;
      reader.readMessage(value,backend_v1_product_groups_pb.ProductGroup.deserializeBinaryFromReader);
      msg.addProductGroups(value);
      break;
    case 10:
      var value = new backend_v1_products_pb.Product;
      reader.readMessage(value,backend_v1_products_pb.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 12:
      var value = new backend_v1_modifier_groups_pb.ModifierGroup;
      reader.readMessage(value,backend_v1_modifier_groups_pb.ModifierGroup.deserializeBinaryFromReader);
      msg.addModifierGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Merchant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Merchant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Merchant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Merchant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDenomination();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDenominationSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      backend_v1_stores_pb.Store.serializeBinaryToWriter
    );
  }
  f = message.getProductGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      backend_v1_product_groups_pb.ProductGroup.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      backend_v1_products_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getModifierGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      backend_v1_modifier_groups_pb.ModifierGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Merchant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp update_timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Merchant.prototype.getUpdateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Merchant} returns this
*/
proto.proto.Merchant.prototype.setUpdateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.clearUpdateTimestamp = function() {
  return this.setUpdateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Merchant.prototype.hasUpdateTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp create_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Merchant.prototype.getCreateTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Merchant} returns this
*/
proto.proto.Merchant.prototype.setCreateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.clearCreateTimestamp = function() {
  return this.setCreateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Merchant.prototype.hasCreateTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.proto.Merchant.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.proto.Merchant.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email_address = 6;
 * @return {string}
 */
proto.proto.Merchant.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string denomination = 7;
 * @return {string}
 */
proto.proto.Merchant.prototype.getDenomination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.setDenomination = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string denomination_symbol = 8;
 * @return {string}
 */
proto.proto.Merchant.prototype.getDenominationSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.setDenominationSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Store stores = 9;
 * @return {!Array<!proto.proto.Store>}
 */
proto.proto.Merchant.prototype.getStoresList = function() {
  return /** @type{!Array<!proto.proto.Store>} */ (
    jspb.Message.getRepeatedWrapperField(this, backend_v1_stores_pb.Store, 9));
};


/**
 * @param {!Array<!proto.proto.Store>} value
 * @return {!proto.proto.Merchant} returns this
*/
proto.proto.Merchant.prototype.setStoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.proto.Store=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Store}
 */
proto.proto.Merchant.prototype.addStores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.proto.Store, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.clearStoresList = function() {
  return this.setStoresList([]);
};


/**
 * repeated ProductGroup product_groups = 11;
 * @return {!Array<!proto.proto.ProductGroup>}
 */
proto.proto.Merchant.prototype.getProductGroupsList = function() {
  return /** @type{!Array<!proto.proto.ProductGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, backend_v1_product_groups_pb.ProductGroup, 11));
};


/**
 * @param {!Array<!proto.proto.ProductGroup>} value
 * @return {!proto.proto.Merchant} returns this
*/
proto.proto.Merchant.prototype.setProductGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.proto.ProductGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ProductGroup}
 */
proto.proto.Merchant.prototype.addProductGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.proto.ProductGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.clearProductGroupsList = function() {
  return this.setProductGroupsList([]);
};


/**
 * repeated Product products = 10;
 * @return {!Array<!proto.proto.Product>}
 */
proto.proto.Merchant.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.proto.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, backend_v1_products_pb.Product, 10));
};


/**
 * @param {!Array<!proto.proto.Product>} value
 * @return {!proto.proto.Merchant} returns this
*/
proto.proto.Merchant.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.proto.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Product}
 */
proto.proto.Merchant.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.proto.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * repeated ModifierGroup modifier_groups = 12;
 * @return {!Array<!proto.proto.ModifierGroup>}
 */
proto.proto.Merchant.prototype.getModifierGroupsList = function() {
  return /** @type{!Array<!proto.proto.ModifierGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, backend_v1_modifier_groups_pb.ModifierGroup, 12));
};


/**
 * @param {!Array<!proto.proto.ModifierGroup>} value
 * @return {!proto.proto.Merchant} returns this
*/
proto.proto.Merchant.prototype.setModifierGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.proto.ModifierGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ModifierGroup}
 */
proto.proto.Merchant.prototype.addModifierGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.proto.ModifierGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Merchant} returns this
 */
proto.proto.Merchant.prototype.clearModifierGroupsList = function() {
  return this.setModifierGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MerchantEvent.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MerchantEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MerchantEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MerchantEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MerchantEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    merchant: (f = msg.getMerchant()) && proto.proto.Merchant.toObject(includeInstance, f),
    changeNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updateMasksList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MerchantEvent}
 */
proto.proto.MerchantEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MerchantEvent;
  return proto.proto.MerchantEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MerchantEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MerchantEvent}
 */
proto.proto.MerchantEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.proto.Merchant;
      reader.readMessage(value,proto.proto.Merchant.deserializeBinaryFromReader);
      msg.setMerchant(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChangeNumber(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.MerchantUpdateMask>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUpdateMasks(values[i]);
      }
      break;
    case 5:
      var value = /** @type {!proto.proto.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MerchantEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MerchantEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MerchantEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MerchantEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMerchant();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Merchant.serializeBinaryToWriter
    );
  }
  f = message.getChangeNumber();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUpdateMasksList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.MerchantEvent.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Merchant merchant = 2;
 * @return {?proto.proto.Merchant}
 */
proto.proto.MerchantEvent.prototype.getMerchant = function() {
  return /** @type{?proto.proto.Merchant} */ (
    jspb.Message.getWrapperField(this, proto.proto.Merchant, 2));
};


/**
 * @param {?proto.proto.Merchant|undefined} value
 * @return {!proto.proto.MerchantEvent} returns this
*/
proto.proto.MerchantEvent.prototype.setMerchant = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.clearMerchant = function() {
  return this.setMerchant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MerchantEvent.prototype.hasMerchant = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 change_number = 3;
 * @return {number}
 */
proto.proto.MerchantEvent.prototype.getChangeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.setChangeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated MerchantUpdateMask update_masks = 4;
 * @return {!Array<!proto.proto.MerchantUpdateMask>}
 */
proto.proto.MerchantEvent.prototype.getUpdateMasksList = function() {
  return /** @type {!Array<!proto.proto.MerchantUpdateMask>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.MerchantUpdateMask>} value
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.setUpdateMasksList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.MerchantUpdateMask} value
 * @param {number=} opt_index
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.addUpdateMasks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.clearUpdateMasksList = function() {
  return this.setUpdateMasksList([]);
};


/**
 * optional EventType event_type = 5;
 * @return {!proto.proto.EventType}
 */
proto.proto.MerchantEvent.prototype.getEventType = function() {
  return /** @type {!proto.proto.EventType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.proto.EventType} value
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool processed = 6;
 * @return {boolean}
 */
proto.proto.MerchantEvent.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.MerchantEvent} returns this
 */
proto.proto.MerchantEvent.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.proto.MerchantUpdateMask = {
  MERCHANT_UPDATE_MASK_UNKNOWN: 0,
  MERCHANT_UPDATE_MASK_TITLE: 1,
  MERCHANT_UPDATE_MASK_PHONE_NUMBER: 2,
  MERCHANT_UPDATE_MASK_EMAIL_ADDRESS: 3
};

/**
 * @enum {number}
 */
proto.proto.MerchantOrderBy = {
  MERCHANT_ORDER_BY_UNKNOWN: 0,
  MERCHANT_ORDER_BY_UPDATE_TIMESTAMP_ASC: 1,
  MERCHANT_ORDER_BY_UPDATE_TIMESTAMP_DESC: 2,
  MERCHANT_ORDER_BY_CREATE_TIMESTAMP_ASC: 3,
  MERCHANT_ORDER_BY_CREATE_TIMESTAMP_DESC: 4
};

goog.object.extend(exports, proto.proto);
