// package: proto
// file: backend/v1/gateway.proto

var backend_v1_gateway_pb = require("../../backend/v1/gateway_pb");
var backend_v1_orders_pb = require("../../backend/v1/orders_pb");
var backend_v1_carts_pb = require("../../backend/v1/carts_pb");
var backend_v1_merchants_pb = require("../../backend/v1/merchants_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var GatewayService = (function () {
  function GatewayService() {}
  GatewayService.serviceName = "proto.GatewayService";
  return GatewayService;
}());

GatewayService.GetMerchant = {
  methodName: "GetMerchant",
  service: GatewayService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_merchants_pb.GetMerchantRequest,
  responseType: backend_v1_merchants_pb.Merchant
};

GatewayService.GetCart = {
  methodName: "GetCart",
  service: GatewayService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.GetCartRequest,
  responseType: backend_v1_carts_pb.Cart
};

GatewayService.ListCheckouts = {
  methodName: "ListCheckouts",
  service: GatewayService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_orders_pb.ListOrdersRequest,
  responseType: backend_v1_orders_pb.ListOrdersResponse
};

exports.GatewayService = GatewayService;

function GatewayServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

GatewayServiceClient.prototype.getMerchant = function getMerchant(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GatewayService.GetMerchant, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GatewayServiceClient.prototype.getCart = function getCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GatewayService.GetCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GatewayServiceClient.prototype.listCheckouts = function listCheckouts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GatewayService.ListCheckouts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.GatewayServiceClient = GatewayServiceClient;

