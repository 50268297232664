// package: proto
// file: backend/v1/carts.proto

var backend_v1_carts_pb = require("../../backend/v1/carts_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CartService = (function () {
  function CartService() {}
  CartService.serviceName = "proto.CartService";
  return CartService;
}());

CartService.CreateCart = {
  methodName: "CreateCart",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.CreateCartRequest,
  responseType: backend_v1_carts_pb.Cart
};

CartService.GetCart = {
  methodName: "GetCart",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.GetCartRequest,
  responseType: backend_v1_carts_pb.Cart
};

CartService.UpdateCart = {
  methodName: "UpdateCart",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.UpdateCartRequest,
  responseType: backend_v1_carts_pb.Cart
};

CartService.DeleteCart = {
  methodName: "DeleteCart",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.DeleteCartRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CartService.ListCartEvents = {
  methodName: "ListCartEvents",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.ListCartEventsRequest,
  responseType: backend_v1_carts_pb.ListCartEventsResponse
};

CartService.ListCarts = {
  methodName: "ListCarts",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.ListCartsRequest,
  responseType: backend_v1_carts_pb.ListCartsResponse
};

CartService.BatchGetCarts = {
  methodName: "BatchGetCarts",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.BatchGetCartsRequest,
  responseType: backend_v1_carts_pb.BatchGetCartsResponse
};

CartService.AdjustCart = {
  methodName: "AdjustCart",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.AdjustCartRequest,
  responseType: backend_v1_carts_pb.Cart
};

CartService.ValidateCart = {
  methodName: "ValidateCart",
  service: CartService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_carts_pb.ValidateCartRequest,
  responseType: backend_v1_carts_pb.Cart
};

exports.CartService = CartService;

function CartServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CartServiceClient.prototype.createCart = function createCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.CreateCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.getCart = function getCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.GetCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.updateCart = function updateCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.UpdateCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.deleteCart = function deleteCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.DeleteCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.listCartEvents = function listCartEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.ListCartEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.listCarts = function listCarts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.ListCarts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.batchGetCarts = function batchGetCarts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.BatchGetCarts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.adjustCart = function adjustCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.AdjustCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CartServiceClient.prototype.validateCart = function validateCart(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CartService.ValidateCart, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CartServiceClient = CartServiceClient;

