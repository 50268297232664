// package: proto
// file: backend/v1/product_groups.proto

var backend_v1_product_groups_pb = require("../../backend/v1/product_groups_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProductGroupService = (function () {
  function ProductGroupService() {}
  ProductGroupService.serviceName = "proto.ProductGroupService";
  return ProductGroupService;
}());

ProductGroupService.CreateProductGroup = {
  methodName: "CreateProductGroup",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.CreateProductGroupRequest,
  responseType: backend_v1_product_groups_pb.ProductGroup
};

ProductGroupService.GetProductGroup = {
  methodName: "GetProductGroup",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.GetProductGroupRequest,
  responseType: backend_v1_product_groups_pb.ProductGroup
};

ProductGroupService.UpdateProductGroup = {
  methodName: "UpdateProductGroup",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.UpdateProductGroupRequest,
  responseType: backend_v1_product_groups_pb.ProductGroup
};

ProductGroupService.DeleteProductGroup = {
  methodName: "DeleteProductGroup",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.DeleteProductGroupRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProductGroupService.ListProductGroupEvents = {
  methodName: "ListProductGroupEvents",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.ListProductGroupEventsRequest,
  responseType: backend_v1_product_groups_pb.ListProductGroupEventsResponse
};

ProductGroupService.ListProductGroups = {
  methodName: "ListProductGroups",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.ListProductGroupsRequest,
  responseType: backend_v1_product_groups_pb.ListProductGroupsResponse
};

ProductGroupService.BatchGetProductGroups = {
  methodName: "BatchGetProductGroups",
  service: ProductGroupService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_groups_pb.BatchGetProductGroupsRequest,
  responseType: backend_v1_product_groups_pb.BatchGetProductGroupsResponse
};

exports.ProductGroupService = ProductGroupService;

function ProductGroupServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProductGroupServiceClient.prototype.createProductGroup = function createProductGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.CreateProductGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupServiceClient.prototype.getProductGroup = function getProductGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.GetProductGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupServiceClient.prototype.updateProductGroup = function updateProductGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.UpdateProductGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupServiceClient.prototype.deleteProductGroup = function deleteProductGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.DeleteProductGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupServiceClient.prototype.listProductGroupEvents = function listProductGroupEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.ListProductGroupEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupServiceClient.prototype.listProductGroups = function listProductGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.ListProductGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductGroupServiceClient.prototype.batchGetProductGroups = function batchGetProductGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductGroupService.BatchGetProductGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProductGroupServiceClient = ProductGroupServiceClient;

