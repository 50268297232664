// package: proto
// file: backend/v1/images.proto

var backend_v1_images_pb = require("../../backend/v1/images_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ImageService = (function () {
  function ImageService() {}
  ImageService.serviceName = "proto.ImageService";
  return ImageService;
}());

ImageService.CreateImage = {
  methodName: "CreateImage",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.CreateImageRequest,
  responseType: backend_v1_images_pb.Image
};

ImageService.GetImage = {
  methodName: "GetImage",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.GetImageRequest,
  responseType: backend_v1_images_pb.Image
};

ImageService.UpdateImage = {
  methodName: "UpdateImage",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.UpdateImageRequest,
  responseType: backend_v1_images_pb.Image
};

ImageService.DeleteImage = {
  methodName: "DeleteImage",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.DeleteImageRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ImageService.ListImageEvents = {
  methodName: "ListImageEvents",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.ListImageEventsRequest,
  responseType: backend_v1_images_pb.ListImageEventsResponse
};

ImageService.ListImages = {
  methodName: "ListImages",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.ListImagesRequest,
  responseType: backend_v1_images_pb.ListImagesResponse
};

ImageService.BatchGetImages = {
  methodName: "BatchGetImages",
  service: ImageService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_images_pb.BatchGetImagesRequest,
  responseType: backend_v1_images_pb.BatchGetImagesResponse
};

exports.ImageService = ImageService;

function ImageServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ImageServiceClient.prototype.createImage = function createImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.CreateImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImageServiceClient.prototype.getImage = function getImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.GetImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImageServiceClient.prototype.updateImage = function updateImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.UpdateImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImageServiceClient.prototype.deleteImage = function deleteImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.DeleteImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImageServiceClient.prototype.listImageEvents = function listImageEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.ListImageEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImageServiceClient.prototype.listImages = function listImages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.ListImages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImageServiceClient.prototype.batchGetImages = function batchGetImages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImageService.BatchGetImages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ImageServiceClient = ImageServiceClient;

