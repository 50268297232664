// package: proto
// file: backend/v1/postings.proto

var backend_v1_postings_pb = require("../../backend/v1/postings_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PostingService = (function () {
  function PostingService() {}
  PostingService.serviceName = "proto.PostingService";
  return PostingService;
}());

PostingService.CreatePosting = {
  methodName: "CreatePosting",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.CreatePostingRequest,
  responseType: backend_v1_postings_pb.Posting
};

PostingService.GetPosting = {
  methodName: "GetPosting",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.GetPostingRequest,
  responseType: backend_v1_postings_pb.Posting
};

PostingService.UpdatePosting = {
  methodName: "UpdatePosting",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.UpdatePostingRequest,
  responseType: backend_v1_postings_pb.Posting
};

PostingService.DeletePosting = {
  methodName: "DeletePosting",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.DeletePostingRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PostingService.ListPostingEvents = {
  methodName: "ListPostingEvents",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.ListPostingEventsRequest,
  responseType: backend_v1_postings_pb.ListPostingEventsResponse
};

PostingService.ListPostings = {
  methodName: "ListPostings",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.ListPostingsRequest,
  responseType: backend_v1_postings_pb.ListPostingsResponse
};

PostingService.BatchGetPostings = {
  methodName: "BatchGetPostings",
  service: PostingService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_postings_pb.BatchGetPostingsRequest,
  responseType: backend_v1_postings_pb.BatchGetPostingsResponse
};

exports.PostingService = PostingService;

function PostingServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PostingServiceClient.prototype.createPosting = function createPosting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.CreatePosting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PostingServiceClient.prototype.getPosting = function getPosting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.GetPosting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PostingServiceClient.prototype.updatePosting = function updatePosting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.UpdatePosting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PostingServiceClient.prototype.deletePosting = function deletePosting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.DeletePosting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PostingServiceClient.prototype.listPostingEvents = function listPostingEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.ListPostingEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PostingServiceClient.prototype.listPostings = function listPostings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.ListPostings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PostingServiceClient.prototype.batchGetPostings = function batchGetPostings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PostingService.BatchGetPostings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PostingServiceClient = PostingServiceClient;

