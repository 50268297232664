// package: proto
// file: backend/v1/product_links.proto

var backend_v1_product_links_pb = require("../../backend/v1/product_links_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProductLinkService = (function () {
  function ProductLinkService() {}
  ProductLinkService.serviceName = "proto.ProductLinkService";
  return ProductLinkService;
}());

ProductLinkService.CreateProductLink = {
  methodName: "CreateProductLink",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.CreateProductLinkRequest,
  responseType: backend_v1_product_links_pb.ProductLink
};

ProductLinkService.GetProductLink = {
  methodName: "GetProductLink",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.GetProductLinkRequest,
  responseType: backend_v1_product_links_pb.ProductLink
};

ProductLinkService.UpdateProductLink = {
  methodName: "UpdateProductLink",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.UpdateProductLinkRequest,
  responseType: backend_v1_product_links_pb.ProductLink
};

ProductLinkService.DeleteProductLink = {
  methodName: "DeleteProductLink",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.DeleteProductLinkRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProductLinkService.ListProductLinkEvents = {
  methodName: "ListProductLinkEvents",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.ListProductLinkEventsRequest,
  responseType: backend_v1_product_links_pb.ListProductLinkEventsResponse
};

ProductLinkService.ListProductLinks = {
  methodName: "ListProductLinks",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.ListProductLinksRequest,
  responseType: backend_v1_product_links_pb.ListProductLinksResponse
};

ProductLinkService.BatchGetProductLinks = {
  methodName: "BatchGetProductLinks",
  service: ProductLinkService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_product_links_pb.BatchGetProductLinksRequest,
  responseType: backend_v1_product_links_pb.BatchGetProductLinksResponse
};

exports.ProductLinkService = ProductLinkService;

function ProductLinkServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProductLinkServiceClient.prototype.createProductLink = function createProductLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.CreateProductLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductLinkServiceClient.prototype.getProductLink = function getProductLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.GetProductLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductLinkServiceClient.prototype.updateProductLink = function updateProductLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.UpdateProductLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductLinkServiceClient.prototype.deleteProductLink = function deleteProductLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.DeleteProductLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductLinkServiceClient.prototype.listProductLinkEvents = function listProductLinkEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.ListProductLinkEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductLinkServiceClient.prototype.listProductLinks = function listProductLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.ListProductLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProductLinkServiceClient.prototype.batchGetProductLinks = function batchGetProductLinks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProductLinkService.BatchGetProductLinks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProductLinkServiceClient = ProductLinkServiceClient;

