// package: proto
// file: backend/v1/payments.proto

var backend_v1_payments_pb = require("../../backend/v1/payments_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PaymentService = (function () {
  function PaymentService() {}
  PaymentService.serviceName = "proto.PaymentService";
  return PaymentService;
}());

PaymentService.CreatePayment = {
  methodName: "CreatePayment",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.CreatePaymentRequest,
  responseType: backend_v1_payments_pb.Payment
};

PaymentService.GetPayment = {
  methodName: "GetPayment",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.GetPaymentRequest,
  responseType: backend_v1_payments_pb.Payment
};

PaymentService.UpdatePayment = {
  methodName: "UpdatePayment",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.UpdatePaymentRequest,
  responseType: backend_v1_payments_pb.Payment
};

PaymentService.DeletePayment = {
  methodName: "DeletePayment",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.DeletePaymentRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PaymentService.ListPaymentEvents = {
  methodName: "ListPaymentEvents",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.ListPaymentEventsRequest,
  responseType: backend_v1_payments_pb.ListPaymentEventsResponse
};

PaymentService.ListPayments = {
  methodName: "ListPayments",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.ListPaymentsRequest,
  responseType: backend_v1_payments_pb.ListPaymentsResponse
};

PaymentService.BatchGetPayments = {
  methodName: "BatchGetPayments",
  service: PaymentService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_payments_pb.BatchGetPaymentsRequest,
  responseType: backend_v1_payments_pb.BatchGetPaymentsResponse
};

exports.PaymentService = PaymentService;

function PaymentServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PaymentServiceClient.prototype.createPayment = function createPayment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.CreatePayment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PaymentServiceClient.prototype.getPayment = function getPayment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.GetPayment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PaymentServiceClient.prototype.updatePayment = function updatePayment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.UpdatePayment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PaymentServiceClient.prototype.deletePayment = function deletePayment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.DeletePayment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PaymentServiceClient.prototype.listPaymentEvents = function listPaymentEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.ListPaymentEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PaymentServiceClient.prototype.listPayments = function listPayments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.ListPayments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PaymentServiceClient.prototype.batchGetPayments = function batchGetPayments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PaymentService.BatchGetPayments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PaymentServiceClient = PaymentServiceClient;

