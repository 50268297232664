// package: proto
// file: backend/v1/hours.proto

var backend_v1_hours_pb = require("../../backend/v1/hours_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var HourService = (function () {
  function HourService() {}
  HourService.serviceName = "proto.HourService";
  return HourService;
}());

HourService.CreateHour = {
  methodName: "CreateHour",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.CreateHourRequest,
  responseType: backend_v1_hours_pb.Hour
};

HourService.GetHour = {
  methodName: "GetHour",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.GetHourRequest,
  responseType: backend_v1_hours_pb.Hour
};

HourService.UpdateHour = {
  methodName: "UpdateHour",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.UpdateHourRequest,
  responseType: backend_v1_hours_pb.Hour
};

HourService.DeleteHour = {
  methodName: "DeleteHour",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.DeleteHourRequest,
  responseType: google_protobuf_empty_pb.Empty
};

HourService.ListHourEvents = {
  methodName: "ListHourEvents",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.ListHourEventsRequest,
  responseType: backend_v1_hours_pb.ListHourEventsResponse
};

HourService.ListHours = {
  methodName: "ListHours",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.ListHoursRequest,
  responseType: backend_v1_hours_pb.ListHoursResponse
};

HourService.BatchGetHours = {
  methodName: "BatchGetHours",
  service: HourService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_hours_pb.BatchGetHoursRequest,
  responseType: backend_v1_hours_pb.BatchGetHoursResponse
};

exports.HourService = HourService;

function HourServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

HourServiceClient.prototype.createHour = function createHour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.CreateHour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HourServiceClient.prototype.getHour = function getHour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.GetHour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HourServiceClient.prototype.updateHour = function updateHour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.UpdateHour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HourServiceClient.prototype.deleteHour = function deleteHour(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.DeleteHour, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HourServiceClient.prototype.listHourEvents = function listHourEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.ListHourEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HourServiceClient.prototype.listHours = function listHours(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.ListHours, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HourServiceClient.prototype.batchGetHours = function batchGetHours(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HourService.BatchGetHours, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.HourServiceClient = HourServiceClient;

