// package: proto
// file: backend/v1/passwords.proto

var backend_v1_passwords_pb = require("../../backend/v1/passwords_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PasswordService = (function () {
  function PasswordService() {}
  PasswordService.serviceName = "proto.PasswordService";
  return PasswordService;
}());

PasswordService.CreatePassword = {
  methodName: "CreatePassword",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.CreatePasswordRequest,
  responseType: backend_v1_passwords_pb.Password
};

PasswordService.GetPassword = {
  methodName: "GetPassword",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.GetPasswordRequest,
  responseType: backend_v1_passwords_pb.Password
};

PasswordService.UpdatePassword = {
  methodName: "UpdatePassword",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.UpdatePasswordRequest,
  responseType: backend_v1_passwords_pb.Password
};

PasswordService.DeletePassword = {
  methodName: "DeletePassword",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.DeletePasswordRequest,
  responseType: google_protobuf_empty_pb.Empty
};

PasswordService.ListPasswordEvents = {
  methodName: "ListPasswordEvents",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.ListPasswordEventsRequest,
  responseType: backend_v1_passwords_pb.ListPasswordEventsResponse
};

PasswordService.ListPasswords = {
  methodName: "ListPasswords",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.ListPasswordsRequest,
  responseType: backend_v1_passwords_pb.ListPasswordsResponse
};

PasswordService.BatchGetPasswords = {
  methodName: "BatchGetPasswords",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.BatchGetPasswordsRequest,
  responseType: backend_v1_passwords_pb.BatchGetPasswordsResponse
};

PasswordService.ValidatePassword = {
  methodName: "ValidatePassword",
  service: PasswordService,
  requestStream: false,
  responseStream: false,
  requestType: backend_v1_passwords_pb.ValidatePasswordRequest,
  responseType: backend_v1_passwords_pb.Password
};

exports.PasswordService = PasswordService;

function PasswordServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PasswordServiceClient.prototype.createPassword = function createPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.CreatePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.getPassword = function getPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.GetPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.updatePassword = function updatePassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.UpdatePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.deletePassword = function deletePassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.DeletePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.listPasswordEvents = function listPasswordEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.ListPasswordEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.listPasswords = function listPasswords(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.ListPasswords, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.batchGetPasswords = function batchGetPasswords(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.BatchGetPasswords, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PasswordServiceClient.prototype.validatePassword = function validatePassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PasswordService.ValidatePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PasswordServiceClient = PasswordServiceClient;

